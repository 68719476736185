import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Modal, Row } from 'reactstrap'
import SubCategoryPart from './SubCategoryPart'

const ResponsesDetail = (props) => {

    const { response } = props
    const [survey] = useState(response.survey)
    const [responses] = useState(response.responses)
    const [modal_standard, setmodal_standard] = useState(false)

    function tog_standard() {
        setmodal_standard(!modal_standard)
    }

    return (
        <>
            <Card outline color="primary" className="border" style={{ cursor: "pointer" }} onClick={() => { tog_standard() }}>
                <CardHeader className="bg-transparent">
                    <h5 className="my-0 text-primary">
                        <i className="bx bx-time-five me-3" />
                        {new Date(response.createdAt).getHours() + ":" + new Date(response.createdAt).getMinutes()}
                    </h5>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <h5>{response.survey.name}</h5>
                        </Col>
                        <Col>
                            <h5 style={{ float: "right" }}>{response.average + "/10"}</h5>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Modal
                isOpen={modal_standard}
                toggle={() => { tog_standard() }}
                size="xl"
                zIndex={1001}
                className="modal-fullscreen"
                style={{ width: "100%" }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                        Détail
                    </h5>
                    <button
                        type="button"
                        onClick={() => setmodal_standard(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    />
                </div>
                <div className="modal-body">
                    <Card>
                        <CardBody>
                            <div className="text-center">
                                <h4>{response?.survey?.name}</h4>
                            </div>
                            <hr />
                            <div className="text-center">
                                <Row>
                                    <Col sm={4}>
                                        <div>
                                            <p className="text-muted mb-2">Magasin</p>
                                            <h5 className="font-size-15">{response?.store?.name}</h5>
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <div>
                                            <p className="text-muted mb-2">Moyenne</p>
                                            <h5 className="font-size-15">{response?.average}</h5>
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <div className="mt-4 mt-sm-0">
                                            <p className="text-muted mb-2">Superviseur</p>
                                            <h5 className="font-size-15">{response?.user?.first_name + " " + response?.user?.last_name}</h5>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <hr />
                            <div style={{ marginBottom: "2rem" }} />
                            {survey?.questionCategories.map((category, index) => (
                                <Card key={index} outline color="primary" className="border">
                                    <CardHeader className="bg-transparent">
                                        <h5>
                                            {category.name}
                                        </h5>
                                    </CardHeader>
                                    <CardBody>
                                        {category?.questionSubCategories?.sort((a, b) => { return a.id - b.id }).map((subCategory, index) => {
                                            return (
                                                <SubCategoryPart
                                                    key={index}
                                                    subCategory={subCategory}
                                                    responses={responses}
                                                />
                                            )
                                        })}
                                    </CardBody>
                                </Card>
                            ))}
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </>
    )
}
export default ResponsesDetail