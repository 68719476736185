import React from 'react'
import { Td, Tr } from 'react-super-responsive-table'
import VisitGallery from './VisitGallery'

const Question = (props) => {

    const { response } = props

    return (
        <Tr>
            <Td> {response?.question.name} </Td>
            <Td> {response?.rate} </Td>
            <Td style={{ width: "67%" }}> {response?.description} </Td>
            <Td>
                {response?.responsePictures.length > 0 &&
                    <VisitGallery
                        pictures={response?.responsePictures}
                    />
                }
            </Td>
        </Tr>
    )
}

export default Question