import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Input, Modal, Row, UncontrolledTooltip } from 'reactstrap'
import VisitGallery from './VisitGallery'

const ReportDetail = (props) => {

    const { report } = props
    const [modal_standard, setmodal_standard] = useState(false)

    function tog_standard() {
        setmodal_standard(!modal_standard)
    }

    return (
        <>
            <Link to={"#"} id="enabletooltip" onClick={() => { tog_standard() }}>
                <button
                    type="button"
                    className="btn btn-outline-primary"
                >
                    <i className="bx bxs-report" />
                </button>
            </Link>
            <UncontrolledTooltip placement="top" target="enabletooltip">
                Consulter le rapport
            </UncontrolledTooltip>
            <Modal
                isOpen={modal_standard}
                toggle={() => { tog_standard() }}
                size="lg"
                zIndex={1001}
                style={{ width: "100%" }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                        Détail
                    </h5>
                    <button
                        type="button"
                        onClick={() => setmodal_standard(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    />
                </div>
                <div className="modal-body">
                    <div className="text-center">
                        <div>
                            <p className="text-muted mb-2">Objets</p>
                            <Row style={{ width: '50%', margin: 'auto' }}>
                                {report.ReportResponseSubjects.map((subject) => (
                                    <Col>
                                        <button type="button" className="btn btn-light position-relative">
                                            {subject.reportSubject.name}
                                        </button>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                    <hr />
                    <h6 style={{ marginTop: "2rem" }}>Compte rendu</h6>
                    <Input
                        type="textarea"
                        readOnly
                        rows={5}
                        defaultValue={report.reportText}
                    >
                    </Input>
                    {report?.reportPictures.length > 0 &&
                        <>
                            <h6 style={{ marginTop: "2rem", marginBottom: "1rem" }}>Images :</h6>
                            <VisitGallery
                                pictures={report?.reportPictures}
                            />
                        </>
                    }
                    <div style={{ marginBottom: "2rem" }} />
                </div>
            </Modal>
        </>
    )
}

export default ReportDetail