import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardTitle, Col, Label, Row, UncontrolledAlert } from 'reactstrap'
import { getStoresAsync } from 'store/pos/actions';
import Select from "react-select";
import { getSurveysAsync } from 'store/survey/actions';
import { MetaTags } from 'react-meta-tags';
import Breadcrumbs from "components/Common/Breadcrumb"
import { governorates } from 'shared/TunisiaGovernorates';
import { getUsersByRole } from 'store/user/services';
import GeneralChart from './components/General';
import { getChart } from 'store/charts/services';
import Chart from './components/Chart';

function Charts() {

    const dispatch = useDispatch()
    const [stores] = useState([])
    const [surveys] = useState([])
    const [merchandisers] = useState([])
    const [errorMessage, setErrorMessage] = useState(null);
    const [selectedGovernorates, setSelectedGovernorates] = useState(null)
    const [selectedStores, setSelectedStores] = useState(null)
    const [selectedSurvey, setSelectedSurvey] = useState(null)
    const [selectedMerchandisers, setSelectedMerchandisers] = useState(null)
    const [selectedType, setSelectedType] = useState("Magasins")
    const [from, setFrom] = useState(null)
    const [to, setTo] = useState(null)
    const [filtredData, setFiltredData] = useState([])
    const storeOptions = [{ options: stores }]
    const governorateOptions = [{ options: governorates }]
    const merchandisersOptions = [{ options: merchandisers }]
    const [filterType] = useState([
        { value: "Magasins", label: "Magasins" },
        { value: "Villes", label: "Villes" },
        { value: "Superviseurs", label: "Superviseurs" }
    ])

    const getChartValues = () => {
        if (!from || !to || !selectedSurvey) {
            setErrorMessage("Date début, Date fin et questionnaire sont obligatoires")
            setTimeout(() => {
                setErrorMessage(null)
            }, 3000);
        } else {
            getChart(
                !selectedStores ? (stores) : (selectedStores),
                selectedSurvey,
                from,
                to,
                !selectedMerchandisers ? (merchandisers) : (selectedMerchandisers),
                !selectedGovernorates ? (governorates) : (selectedGovernorates)
            ).then((res) => {
                setFiltredData(res.data);
            })
        }
    }

    useEffect(async () => {
        dispatch(await getSurveysAsync()).payload.surveys.forEach(element => {
            surveys.push({ value: element.id, label: element.name })
        });

        dispatch(await getStoresAsync()).payload.stores.forEach(element => {
            stores.push({ value: element.id, label: element.name + " | " + element.address + ", " + element.postal_code + " - " + element.governorate })
        });

        getUsersByRole("superviseur").then((data) => {
            data.forEach(element => {
                merchandisers.push({ value: element.id, label: element.first_name + " " + element.last_name })
            });
        })
    }, [])

    const styles = {
        control: (provided, state) => ({
            backgroundColor: "#2a3042",
        }),
        placeholder: (provided, state) => ({
            backgroundColor: "#2a3042"
        }),
    };

    return (
        <div className="page-content">
            <MetaTags>
                <title>first chart</title>
            </MetaTags>
            <div className="container-fluid">
                <Breadcrumbs title="Charts" breadcrumbItem="first chart" />
                <Card style={{ minHeight: "44.7rem" }}>
                    <CardBody>
                        <CardTitle>first chart</CardTitle>
                        <div style={{ marginBottom: "3rem", marginTop: "2rem" }}>
                            <Row>
                                <Col lg={2} md={6}>
                                    <Label>Villes</Label>
                                    <Select
                                        placeholder="Villes..."
                                        value={selectedGovernorates}
                                        options={governorateOptions}
                                        isMulti={true}
                                        classNamePrefix="select2-selection"
                                        /* styles={styles} */
                                        onChange={(e) => { setSelectedGovernorates(e); }}
                                    />
                                </Col>
                                <Col lg={2} md={6}>
                                    <Label>Magasins</Label>
                                    <Select
                                        placeholder="Magasins..."
                                        value={selectedStores}
                                        isMulti={true}
                                        onChange={(e) => { setSelectedStores(e) }}
                                        options={storeOptions}
                                        /* styles={styles} */
                                        classNamePrefix="select2-selection"
                                    />
                                </Col>
                                <Col lg={2} md={6}>
                                    <Label>
                                        Questionnaire
                                        <span style={{ fontSize: "15px", color: "red" }}>*</span>
                                    </Label>
                                    <Select
                                        placeholder="Questionnaires..."
                                        options={surveys}
                                        classNamePrefix="select2-selection"
                                        onChange={(e) => { setSelectedSurvey(e.value) }}
                                    />
                                </Col>
                                <Col lg={2} md={6}>
                                    <Label>Superviseurs</Label>
                                    <Select
                                        placeholder="Superviseurs..."
                                        value={selectedMerchandisers}
                                        isMulti={true}
                                        options={merchandisersOptions}
                                        classNamePrefix="select2-selection"
                                       /*  styles={styles} */
                                        onChange={(e) => { setSelectedMerchandisers(e) }}
                                    />
                                </Col>
                                <Col lg={2} md={6}>
                                    <Label>
                                        Date début
                                        <span style={{ fontSize: "15px", color: "red" }}>*</span>
                                    </Label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="example-date-input"
                                        onChange={(e) => { setFrom(e.target.value); }}
                                    />
                                </Col>
                                <Col lg={2} md={6}>
                                    <Label>
                                        Date fin
                                        <span style={{ fontSize: "15px", color: "red" }}>*</span>
                                    </Label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="example-date-input"
                                        onChange={(e) => { setTo(e.target.value); }}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1rem" }}>
                                <Col lg={4} />
                                <Col lg={2} md={6}>
                                    <Label>Grouper par</Label>
                                    <Select
                                        placeholder="Groupes..."
                                        options={filterType}
                                        defaultValue={filterType[0]}
                                        classNamePrefix="select2-selection"
                                        onChange={(e) => { setSelectedType(e.value) }}
                                    />
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        style={{ marginTop: "2rem", float: window.innerWidth < 769 && "right" }}
                                        onClick={() => { getChartValues() }}
                                    >
                                        Valider
                                    </button>
                                </Col>
                            </Row>
                        </div>
                        {errorMessage &&
                            <UncontrolledAlert
                                color="danger"
                                className="alert-dismissible fade show"
                                role="alert"
                                style={{ width: window.innerWidth > 769 && "25%", marginLeft: window.innerWidth > 769 && "33%" }}
                            >
                                <i className="mdi mdi-block-helper me-2"></i> {errorMessage}
                            </UncontrolledAlert>
                        }
                        {filtredData.length > 0 &&
                            <Row>
                                <Col>
                                    <Chart
                                        selectedType={selectedType}
                                        filtredData={filtredData}
                                    />
                                </Col>
                            </Row>
                        }
                        {/* {filtredData.length > 0 && selectedType === "Ville" &&
                            <Row>
                                <Col>
                                    <GeneralChart
                                        selectedGovernorate={selectedGovernorates}
                                        filtredData={filtredData}
                                    />
                                </Col>
                            </Row>
                        } */}
                    </CardBody>
                </Card>
            </div>
        </div >
    )
}
export default Charts