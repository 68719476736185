import React, { useEffect, useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import LoadingComponent from 'shared/LoadingComponent'
import { getNotificationConfigList, upsertNotificationConfig } from 'store/notification/services'
import CreateNotif from './components/CreateNotif'
import $ from 'jquery';
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { getStoresAsync } from 'store/pos/actions'
import { getUsersByRole } from 'store/user/services'
import NotifStandard from './components/NotifStandard'

const Notifications = () => {

    const dispatch = useDispatch();
    const [notificationConfigs, setNotificationConfigs] = useState([])
    const [users, setUsers] = useState([])
    const [stores, setStores] = useState([])

    const getNotifications = async () => {
        await getNotificationConfigList()
            .then((data) => {
                setNotificationConfigs(data);
            })
    }

    const updateNotif = (notification) => {
        Swal.fire({
            title: 'êtes-vous sûr?',
            text: "Modifier la notification?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui'
        }).then((result) => {
            if (result.isConfirmed) {
                upsertNotificationConfig(notification)
                    .then(async () => {
                        await getNotifications()
                    })
            }
        })
    }

    useEffect(async () => {
        setStores(dispatch(await getStoresAsync()).payload.stores)
        getUsersByRole("superviseur")
            .then((data) => { setUsers(data); })
        await getNotifications()
        $('#mydatatable').DataTable()
    }, [])

    return (
        <div className="page-content">
            <MetaTags>
                <title>Notifications</title>
            </MetaTags>
            <div className="container-fluid">
                <Breadcrumbs title="Notifications" breadcrumbItem="Liste des notifications" />
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <CardTitle>Notifications</CardTitle>
                                    </Col>

                                    <Col>
                                        <CreateNotif
                                            users={users}
                                            stores={stores}
                                            getNotifications={getNotifications}
                                        />
                                        <NotifStandard />
                                    </Col>
                                </Row>
                                {notificationConfigs.length > 0 ? (
                                    <div className="table-rep-plugin" style={{ marginTop: "2rem" }}>
                                        <div
                                            className="table-responsive mb-0"
                                            data-pattern="priority-columns"
                                        >
                                            <Table
                                                id="mydatatable"
                                                className="table table-striped table-bordered"
                                            >
                                                <Thead>
                                                    <Tr>
                                                        <Th>Type</Th>
                                                        <Th>Condition</Th>
                                                        <Th>Type de pointage</Th>
                                                        <Th>Heure de pointage</Th>
                                                        <Th>Email</Th>
                                                        <Th>SMS</Th>
                                                        <Th>activé</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {notificationConfigs.map((notif, index) => (
                                                        <Tr key={index}>
                                                            <Td>{notif.type}</Td>
                                                            <Td>{notif.condition_score ? (notif.condition_operator + " " + notif.condition_score) : ("")}</Td>
                                                            <Td>{notif.pointage_type}</Td>
                                                            <Td>
                                                                {notif.pointage_hour && notif.pointage_minute &&
                                                                    <>
                                                                        {
                                                                            (parseInt(notif.pointage_minute) > 10) ? (
                                                                                notif.pointage_hour + ":" + notif.pointage_minute
                                                                            ) : (
                                                                                notif.pointage_hour + ":0" + notif.pointage_minute
                                                                            )}
                                                                    </>
                                                                }
                                                            </Td>
                                                            <Td>
                                                                <div className="form-check form-switch form-switch-md mb-3" >
                                                                    <input
                                                                        type="checkbox"
                                                                        style={{ cursor: "pointer" }}
                                                                        className="form-check-input"
                                                                        checked={notif.email}
                                                                        onChange={() => { updateNotif({ ...notif, email: !notif.email }) }}
                                                                    />
                                                                </div>
                                                            </Td>
                                                            <Td>
                                                                <div className="form-check form-switch form-switch-md mb-3" >
                                                                    <input
                                                                        type="checkbox"
                                                                        style={{ cursor: "pointer" }}
                                                                        className="form-check-input"
                                                                        checked={notif.sms}
                                                                        onChange={() => { updateNotif({ ...notif, sms: !notif.sms }) }}
                                                                    />
                                                                </div>
                                                            </Td>
                                                            <Td>
                                                                <div className="form-check form-switch form-switch-md mb-3" >
                                                                    <input
                                                                        type="checkbox"
                                                                        style={{ cursor: "pointer" }}
                                                                        className="form-check-input"
                                                                        checked={notif.enabled}
                                                                        onChange={() => { updateNotif({ ...notif, enabled: !notif.enabled }) }}
                                                                    />
                                                                </div>
                                                            </Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                ) : (
                                    <LoadingComponent />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div >
    )
}

export default Notifications