import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { useDispatch } from "react-redux";
import { getStoresAsync } from "store/pos/actions";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import UpdateModal from "./components/UpdateModal";
import $ from 'jquery';
import CreateModal from "./components/CreateModal";
import DetailModal from "./components/DetailModal";
import StoreGallery from "./components/StoreGallery";
import LoadingComponent from "shared/LoadingComponent";
import StoreHistory from "./components/StoreHistory";
import { userRole } from "components/CommonForBoth/TopbarDropdown/ProfileMenu";
import { useHistory } from "react-router-dom";

export default function PosList() {

    const dispatch = useDispatch();
    const history = useHistory()
    const [stores, setStores] = useState([])

    const getStores = async () => {
        return dispatch(await getStoresAsync()).payload;
    }

    const getList = async () => {
        await getStores().then((res) => {
            setStores(res.stores);
        })
    }

    useEffect(async () => {
        await getList()
        $('#mydatatable').DataTable()
    }, [])

    return (
        <div className="page-content">
            <MetaTags>
                <title>Magasins</title>
            </MetaTags>
            <div className="container-fluid">
                <Breadcrumbs title="Magasins" breadcrumbItem="Liste des magasins" />
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>Magasins</CardTitle>
                                <CreateModal getList={getList} />
                                {stores.length > 0 ? (
                                    <div className="table-rep-plugin" style={{ marginTop: "2rem" }}>
                                        <div
                                            className="table-responsive mb-0"
                                            data-pattern="priority-columns"
                                        >
                                            <Table
                                                id="mydatatable"
                                                className="table table-striped table-bordered"
                                            >
                                                <Thead>
                                                    <Tr>
                                                        <Th>Nom</Th>
                                                        <Th>Adresse</Th>
                                                        <Th>Gouvernorat</Th>
                                                        <Th>Email</Th>
                                                        <Th>Numéro de téléphone</Th>
                                                        <Th>Actons</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {stores?.map((store, index) => (
                                                        <Tr key={index}/*  style={{ backgroundColor: "#2a3042" }} */>
                                                            <Td> {store.name} </Td>
                                                            <Td> {store.address} </Td>
                                                            <Td> {store.governorate} </Td>
                                                            <Td> {store.email} </Td>
                                                            <Td> {store.phone_number} </Td>
                                                            <Td>
                                                                <Row>
                                                                    <Col xl={3} lg={3}>
                                                                        <UpdateModal
                                                                            currentStore={store}
                                                                            getList={getList}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={3} lg={3}>
                                                                        <DetailModal
                                                                            currentStore={store}
                                                                        />
                                                                    </Col>
                                                                    <Col xl={3} lg={3}>
                                                                        {store?.storePictures?.length > 0 &&
                                                                            <StoreGallery
                                                                                pictures={store?.storePictures}
                                                                            />
                                                                        }
                                                                    </Col>
                                                                    <Col xl={3} lg={3}>
                                                                        <StoreHistory
                                                                            storeId={store.id}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                ) : (
                                    <LoadingComponent />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}