import { getSurveys } from "./services";

export const GET_SURVEYS = "GET_SURVEYS"

/**
 * 
 * @returns stores list
 */
export const getSurveysAsync = async () => {
  try {
    const res = await getSurveys();
    return {
      type: GET_SURVEYS,
      payload: { surveys: res },
    }
  } catch (error) {
    return error
  }

}