import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getSurveyResponses } from 'store/dashboard/services';


function SurveyAVGStore() {

    const [data, setData] = useState([])

    function groupBy(items) {
        return items.reduce((acc, curr) => {
            if (curr.store) {
                const { name } = curr.store;
                const currentItems = acc[name];

                return {
                    ...acc,
                    [name]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;
        }, {});
    }

    useEffect(() => {
        getSurveyResponses()
            .then((data) => {
                
                Object.values(groupBy(data)).forEach((visits) => {
                    let avg = 0
                    visits.forEach(visit => {
                        avg = (avg + visit.average)
                    });
                    setData(data => [
                        ...data,
                        { name: visits[0].store.name, avg: parseFloat(avg / visits.length).toFixed(2), visits: visits }
                    ])
                })
            })   


        




    }, [])

    return (
        <Card>
            <CardBody>
                <CardTitle className="mb-4">Performances</CardTitle>
                <div style={{ width: '100%', height: "31.95rem", overflowY: "auto" }}>
                    <ResponsiveContainer>
                        <BarChart
                            data={data}
                            layout="vertical"
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            barSize={30}
                        >
                            <XAxis type="number" domain={[0, 10]} ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} />
                            <YAxis  type="category" dataKey="name"/>
                            <Tooltip />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Bar dataKey="avg" fill="#8884d8" background={{ fill: '#eee' }} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardBody>
        </Card>
    )
}

export default SurveyAVGStore