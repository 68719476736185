import React, { useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Col, Modal } from 'reactstrap';
import questionnaireImage from "../../../assets/images/survey/questionnaire.jpg"
import SubCategoryPart from './SubCategoryPart';

const SurveyDetail = (props) => {

    const { survey } = props
    const [modal_fullscreen, setmodal_fullscreen] = useState(false)

    function tog_fullscreen() {
        setmodal_fullscreen(!modal_fullscreen)
    }

    return (
        <>
            <Col xl="2" sm="6" style={{ cursor: "pointer" }} onClick={() => { tog_fullscreen(); }}>
                <Card className="text-center">
                    <CardBody style={{ height: "15rem" }}>
                        <img
                            style={{ width: "100%", height: "100%" }}
                            src={questionnaireImage}
                        />
                    </CardBody>
                    <CardFooter className="bg-transparent border-top">
                        <h5> {survey.name} </h5>
                    </CardFooter>
                </Card>
            </Col>
            <Modal
                size="xl"
                isOpen={modal_fullscreen}
                toggle={() => { tog_fullscreen() }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                    >
                        {survey.name}
                    </h5>
                    <button
                        onClick={() => { setmodal_fullscreen(false) }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {survey.questionCategories?.map((category, index) => (
                        <Card key={index} outline color="primary" className="border">
                            <CardHeader className="bg-transparent">
                                <h5>
                                    {category.name}
                                </h5>
                            </CardHeader>
                            <CardBody>
                                {category.questionSubCategories?.sort((a, b) => { return a.id - b.id }).map((subCategory, index) => (
                                    <SubCategoryPart
                                        key={index}
                                        subCategory={subCategory}
                                    />
                                ))}
                            </CardBody>
                        </Card>
                    ))}
                </div>
            </Modal>
        </>
    )
}

export default SurveyDetail;