import VisitGallery from 'pages/PlanningControl/components/VisitGallery'
import React, { useState } from 'react'
import { Card, CardBody, CardHeader, Col, Input, Modal, Row } from 'reactstrap'

const ReportDetail = (props) => {

    const { report } = props
    const [modal_standard, setmodal_standard] = useState(false)

    function tog_standard() {
        setmodal_standard(!modal_standard)
    }

    return (
        <>
            <Card outline color="primary" className="border" style={{cursor: "pointer"}} onClick={() => { tog_standard() }}>
                <CardHeader className="bg-transparent">
                    <Row>
                        <Col>
                            <h5 className="my-0 text-primary">
                                <i className="bx bx-time-five me-3" />
                                {new Date(report.createdAt).getHours() + ":" + new Date(report.createdAt).getMinutes()}
                            </h5>
                        </Col>
                        <Col>
                            <h5 className="my-0 text-primary" style={{ float: "right" }}>{"CR"}</h5>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <h5>{report.subjects}</h5>
                </CardBody>
            </Card>
            <Modal
                isOpen={modal_standard}
                toggle={() => { tog_standard() }}
                size="lg"
                zIndex={1001}
                style={{ width: "100%" }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                        Détail
                    </h5>
                    <button
                        type="button"
                        onClick={() => setmodal_standard(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    />
                </div>
                <div className="modal-body">
                    <div className="text-center">
                        <div>
                            <p className="text-muted mb-2">Objets</p>
                            <h5>{report.subjects}</h5>
                        </div>
                    </div>
                    <hr />
                    <h6 style={{ marginTop: "2rem" }}>Compte rendu</h6>
                    <Input
                        type="textarea"
                        readOnly
                        rows={5}
                        defaultValue={report.reportText}
                    >
                    </Input>
                    {report?.reportPictures.length > 0 &&
                        <>
                            <h6 style={{ marginTop: "2rem", marginBottom: "1rem" }}>Images :</h6>
                            <VisitGallery
                                pictures={report?.reportPictures}
                            />
                        </>
                    }
                    <div style={{ marginBottom: "2rem" }} />
                </div>
            </Modal>
        </>
    )
}

export default ReportDetail