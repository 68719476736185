import React from 'react'
import { Td, Tr } from 'react-super-responsive-table'
import { Progress } from 'reactstrap'

function RowPerformance(props) {

    const { visits } = props

    let nbPictures = 0
    let nbSurveyResponses = 0
    let nbPlannedVisits = 0
    let nbInplannedVisits = 0
    let nbRealisedVisits = 0
    let sumSurveys = 0
    let startLogs = [];
    let endLogs = [];

    visits.forEach(visit => {
        nbSurveyResponses = nbSurveyResponses + visit?.surveyResponses.length
        if (visit.planned) {
            nbPlannedVisits = nbPlannedVisits + 1
        } else {
            nbInplannedVisits = nbInplannedVisits + 1
        }
        if (visit?.surveyResponses.length > 0) {
            nbRealisedVisits = nbRealisedVisits + 1
        }
        if (visit?.start && visit?.end) {
            startLogs.push(new Date(visit?.start).getHours() + ":" + new Date(visit?.start).getMinutes() + ":" + new Date(visit?.start).getSeconds())
            endLogs.push(new Date(visit?.end).getHours() + ":" + new Date(visit?.end).getMinutes() + ":" + new Date(visit?.end).getSeconds())
            //startLogs.push(new Date(new Date(visit.start).getFullYear(), new Date(visit.start).getUTCMonth() + 1, new Date(visit.start).getDate(), new Date(visit.start).getUTCHours(), new Date(visit.start).getMinutes(), new Date(visit.start).getSeconds()),)
            //endLogs.push(new Date(new Date(visit.end).getFullYear(), new Date(visit.end).getUTCMonth() + 1, new Date(visit.end).getDate(), new Date(visit.end).getUTCHours(), new Date(visit.end).getMinutes(), new Date(visit.end).getSeconds()),)
        }
        visit?.surveyResponses?.forEach(surveyResponse => {
            sumSurveys = sumSurveys + surveyResponse.average
            surveyResponse?.responses.forEach(response => {
                nbPictures = nbPictures + response.responsePictures.length
            });
        });

    });

    function getAverageTime(times) {
        var count = times.length
        var timesInSeconds = [];
        for (var i = 0; i < count; i++) {
            var pieces = times[i].split(':');
            var ampm = pieces[2].split(' ');
            var hrs = Number(pieces[0]);
            var mins = Number(pieces[1]);
            var secs = Number(ampm[0]);
            var ampm = ampm[1];
            var totalSecs = hrs * 60 * 60;
            totalSecs += mins * 60;
            totalSecs += secs;
            timesInSeconds[i] = totalSecs;
        }
        var total = 0;
        for (var j = 0; j < count; j++) {
            total = total + Number(timesInSeconds[j]);
        }
        var avg = Math.round(total / count);
        var avgMins = Math.floor(avg / 60);
        var avgSecs = avg - (60 * avgMins);
        var avgHrs = Math.floor(avgMins / 60);
        avgMins = avgMins - (60 * avgHrs);
        avgSecs = ('0' + avgSecs).slice(-2);
        avgMins = ('0' + avgMins).slice(-2);
        return { avgHrs: avgHrs, avgMins: avgMins, avgSecs: avgSecs }
    }

    return (
        <Tr>
            <Td><p style={{marginTop: "1rem"}}>{visits[0]?.user?.first_name + " " + visits[0]?.user?.last_name}</p></Td>
            <Td><p style={{marginTop: "1rem"}}>{(getAverageTime(startLogs).avgHrs*60)+(parseInt(getAverageTime(startLogs).avgMins))-8*60+" Minutes"}</p></Td>
            <Td><p style={{marginTop: "1rem"}}>{getAverageTime(endLogs).avgHrs + ":" + getAverageTime(endLogs).avgMins}</p></Td>
            <Td><p style={{marginTop: "1rem"}}>{nbPlannedVisits}</p></Td>
            <Td><p style={{marginTop: "1rem"}}>{nbInplannedVisits}</p></Td>
            <Td><p style={{marginTop: "1rem"}}>{nbRealisedVisits}</p></Td>
            <Td><p style={{marginTop: "1rem"}}>{nbSurveyResponses}</p></Td>
            <Td><p style={{marginTop: "1rem"}}>{parseFloat(sumSurveys / nbSurveyResponses).toFixed(2)}</p></Td>
            <Td><p style={{marginTop: "1rem"}}>{nbPictures}</p></Td>
            <Td>
                <div className="text-center">
                    <h6 style={{ marginTop: "0.6rem" }}>{parseFloat((nbRealisedVisits / nbPlannedVisits) * 100).toFixed(2) + "%"}</h6>
                    <Progress
                        color={parseFloat((nbRealisedVisits / nbPlannedVisits) * 100).toFixed(2) < 80 ? "danger" : "success"}
                        value={parseFloat((nbRealisedVisits / nbPlannedVisits) * 100).toFixed(2)}
                        style={{ height: "1rem", width: "81.5%", marginLeft: "17.5%", backgroundColor: "white" }}
                    >
                    </Progress>
                    <p>{nbRealisedVisits + "/" + nbPlannedVisits}</p>
                </div>
            </Td>
        </Tr>
    )
}

export default RowPerformance