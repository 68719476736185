import React from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import ReportDetail from './ReportDetail'
import ResponsesDetail from './ResponsesDetail'

const DayVisit = (props) => {

    const { visit, surveyResponses } = props

    return (
        <Card
            outline
            color={surveyResponses.length === 0 ? ("danger") : ("success")}
            className="border"
            style={{ width: "11rem" }}
        >
            <CardHeader className="bg-Transparent" style={{ backgroundColor: surveyResponses.length === 0 ? ("#dc3545") : ("#198754") }}>
                <h6 style={{ color: "white" }}>{visit.store.name}</h6>
                {visit.start &&
                    <i className='bx bx-log-in' style={{ color: "white", fontSize: '1rem' }}>
                        {new Date(visit.start).getMinutes() < 10 ? (' ' + new Date(visit.start).getHours() + ':' + '0' + new Date(visit.start).getMinutes()) : (' ' + new Date(visit.start).getHours() + ':' + new Date(visit.start).getMinutes())}
                    </i>
                }
                {visit.end &&
                    <i className='bx bx-log-out' style={{ color: "white", fontSize: '1rem', float: 'right' }}>
                        {' ' + new Date(visit.end).getHours() + ':' + new Date(visit.end).getMinutes()}
                    </i>
                }
            </CardHeader>
            {surveyResponses.length > 0 &&
                <CardBody>
                    <Row>
                        {surveyResponses.map((response, index) => (
                            <Col key={index} style={{ marginBottom: "0.5rem" }}>
                                <ResponsesDetail
                                    key={index}
                                    response={response}
                                    store={response.store}
                                />
                            </Col>
                        ))}
                        {visit.reports.map((report, i) => (
                            <Col key={i} style={{ marginBottom: "0.5rem" }}>
                                <ReportDetail
                                    report={report}
                                />
                            </Col>
                        ))}
                    </Row>
                </CardBody>
            }
        </Card>
    )
}
export default DayVisit