import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Media, Row } from 'reactstrap'

function GeneralInformations(props) {

    const { visits } = props
    const [nbRealisedVisits, setNbRealisedVisits] = useState(0)
    const [nbSurveys, setNbSurveys] = useState(0)
    const [nbPictures, setNbPictures] = useState(0)
    let realisedVisits = 0
    let pictures = 0
    let surveys = 0

    useEffect(() => {
        if (visits.length > 0) {
            visits.forEach(visit => {
                if (visit?.surveyResponses.length > 0) {
                    realisedVisits = realisedVisits + 1
                }
                visit?.surveyResponses?.forEach(surveyResponse => {
                    surveys = surveys + 1
                    surveyResponse?.responses.forEach(response => {
                        pictures = pictures + response.responsePictures.length
                    });
                });

            });
        }
        setNbRealisedVisits(realisedVisits);
        setNbPictures(pictures)
        setNbSurveys(surveys)
    }, [visits])


    return (
        <Row>
            <Col md="4">
                <Card className="mini-stats-wid">
                    <CardBody>
                        <Media>
                            <Media body>
                                <p className="text-muted fw-medium">
                                    Visites réalisées
                                </p>
                                <h4 className="mb-0">{nbRealisedVisits}</h4>
                            </Media>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                        className={
                                            "bx bx-store-alt font-size-24"
                                        }
                                    ></i>
                                </span>
                            </div>
                        </Media>
                    </CardBody>
                </Card>
            </Col>
            <Col md="4">
                <Card className="mini-stats-wid">
                    <CardBody>
                        <Media>
                            <Media body>
                                <p className="text-muted fw-medium">
                                    Audits réalisés
                                </p>
                                <h4 className="mb-0">{nbSurveys}</h4>
                            </Media>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                        className={
                                            "bx bx-copy-alt font-size-24"
                                        }
                                    ></i>
                                </span>
                            </div>
                        </Media>
                    </CardBody>
                </Card>
            </Col>
            <Col md="4">
                <Card className="mini-stats-wid">
                    <CardBody>
                        <Media>
                            <Media body>
                                <p className="text-muted fw-medium">
                                    Photos prises
                                </p>
                                <h4 className="mb-0">{nbPictures}</h4>
                            </Media>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                        className={
                                            "bx bx-photo-album font-size-24"
                                        }
                                    ></i>
                                </span>
                            </div>
                        </Media>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default GeneralInformations