import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody, CardTitle, Progress } from "reactstrap"
import { Link } from "react-router-dom"

import ApexRadial from "./ApexRadial"

const MonthlyEarning = (props) => {

  const { visits } = props

  const [nbRealisedVisits, setNbRealisedVisits] = useState(0)
  const [nbPlannedVisits, setNbPlannedVisits] = useState(0)
  const [nbSurveys, setNbSurveys] = useState(0)
  const [nbPictures, setNbPictures] = useState(0)
  let realisedVisits = 0
  let plannedVisits = 0
  let pictures = 0
  let surveys = 0

  useEffect(() => {
    if (visits.length > 0) {
      visits.forEach(visit => {
        if (visit.planned) {
          plannedVisits = plannedVisits + 1
        }
        if (visit?.surveyResponses.length > 0) {
          realisedVisits = realisedVisits + 1
        }
        visit?.surveyResponses?.forEach(surveyResponse => {
          surveys = surveys + 1
          surveyResponse?.responses.forEach(response => {
            pictures = pictures + response.responsePictures.length
          });
        });

      });
    }
    setNbRealisedVisits(realisedVisits);
    setNbPlannedVisits(plannedVisits)
    setNbPictures(pictures)
    setNbSurveys(surveys)
  }, [visits])

  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Chiffres du mois </CardTitle>
          <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-calendar text-primary display-4" />
            </div>
            <h3>{new Date().getMonth() >= 9 ? (new Date().getMonth() + 1) : ("0" + parseInt(new Date().getMonth() + 1)) + "-" + new Date().getFullYear()}</h3>
          </div>
          <div className="table-responsive mt-4" style={{marginBottom: "1.25rem"}}>
            <table className="table align-middle table-nowrap">
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">Visites planifiées</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">{nbPlannedVisits}</h5>
                  </td>
                  <td>
                    <Progress
                      value="100"
                      color="primary"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">Visites réalisées</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">{nbRealisedVisits}</h5>
                  </td>
                  <td>
                    <Progress
                      value="100"
                      color="success"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">Questionnaires réalisées</p>
                  </td>
                  <td>
                    <h5 className="mb-0">{nbSurveys}</h5>
                  </td>
                  <td>
                    <Progress
                      value="100"
                      color="danger"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">Photos prises</p>
                  </td>
                  <td>
                    <h5 className="mb-0">{nbPictures}</h5>
                  </td>
                  <td>
                    <Progress
                      value="100"
                      color="warning"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonthlyEarning
