import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Button, CardHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Subcategory from './Subcategory';

const CategoriePart = (props) => {

    const { category, index, deleteCategory } = props
    const [subCategories, setSubCategories] = useState([]);
    const [btnprimary1, setBtnprimary1] = useState(false)

    const newSubCategory = () => {
        setSubCategories([...subCategories, { name: "", questions: [] }])
        category?.subCategories.push({ name: "", questions: [] })
    }

    const deleteSubCategory = (i) => {
        setSubCategories(subCategories.filter((q, index) => i !== index))
        category.subCategories = category.subCategories.filter((q, index) => i !== index)
    }

    return (
        <Card outline color="primary" className="border">
            <CardHeader className="bg-transparent">
                <Row>
                    <Col />
                    <Col>
                        <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                        >
                            Catégorie
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Nom de la catégorie"
                            defaultValue={category.name}
                            onChange={(e) => { category.name = e.target.value }}
                        />
                    </Col>
                    <Col>
                        <Dropdown
                            isOpen={btnprimary1}
                            toggle={() => setBtnprimary1(!btnprimary1)}
                            style={{ marginTop: "2.3rem" }}
                        >
                            <DropdownToggle tag="button" className="btn btn-primary">
                                Actions <i className="mdi mdi-chevron-down" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={() => { newSubCategory() }}
                                >
                                    <i className="bx bx-plus" /> sous-catégorie
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => { deleteCategory(index) }}
                                >
                                    <i className="bx bx-x" />Supprimer catégorie
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                {category.subCategories.map((subcategory, index) => (
                    <Subcategory
                        key={index}
                        index={index}
                        subcategory={subcategory}
                        deleteSubCategory={deleteSubCategory}
                    />
                ))}
            </CardBody>
        </Card>
    )
}
export default CategoriePart