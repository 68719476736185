import PropTypes from 'prop-types'
import React from "react"
import { Row, Col } from "reactstrap"
import WelcomeComp from "../WelcomeComp"
import MonthlyEarning from "../MonthlyEarning"
import SocialSource from "../SocialSource"
import { withTranslation } from "react-i18next"
import GeneralInformations from '../GeneralInformations'
import TodaySupervisorPerformance from '../TodaySupervisorPerformance'
import SurveyAVGStore from '../SurveyAVGStore'
import LoadingComponent from 'shared/LoadingComponent'

const DashboardAdmin = (props) => {

  const { connectedUser, visits } = props

  return (
    <>
      {visits.length > 0 ? (
        <>
          <Row>
            <Col xl="4">
              <WelcomeComp
                connectedUser={connectedUser}
                visits={visits.filter((visit) => (
                  (new Date(visit.day).getFullYear() === new Date().getFullYear())
                  &&
                  (new Date(visit.day).getMonth() === new Date().getMonth())
                  &&
                  (new Date(visit.day).getDate() === new Date().getDate())
                ))}
              />
              <MonthlyEarning
                visits={visits.filter((visit) => (
                  (new Date(visit.day).getFullYear() === new Date().getFullYear())
                  &&
                  (new Date(visit.day).getMonth() === new Date().getMonth())
                ))}
              />
            </Col>
            <Col xl="8">
              <GeneralInformations
                visits={visits.filter((visit) => (
                  (new Date(visit.day).getFullYear() === new Date().getFullYear())
                  &&
                  (new Date(visit.day).getMonth() === new Date().getMonth())
                ))}
              />
              <Row>
                <Col>
                  <SurveyAVGStore />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <TodaySupervisorPerformance
                visits={visits.filter((visit) => (
                  (new Date(visit.day) < new Date())
                ))}
              />
            </Col>
            <Col>
              <SocialSource
                visits={visits.filter((visit) => (
                  (new Date(visit.day).getFullYear() === new Date().getFullYear())
                  &&
                  (new Date(visit.day).getMonth() === new Date().getMonth())
                  &&
                  (new Date(visit.day).getDate() === new Date().getDate())
                ))}
              />
            </Col>
          </Row>
        </>
      ) : (
        <LoadingComponent />
      )
      }
    </>
  )
}

DashboardAdmin.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(DashboardAdmin)