import React, { useState } from 'react'
import { Modal } from 'reactstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"

function NotifStandard() {

    const [modal_standard, setmodal_standard] = useState(false)

    function tog_standard() {
        setmodal_standard(!modal_standard)
    }

    return (
        <>
            <i
                className="bx bx-bell"
                style={{ fontSize: "20px", float: "right", marginTop: "4px", marginRight: "0.5rem", cursor: "pointer", color: '#556EE6' }}
                onClick={() => { tog_standard(); }}
                data-toggle="modal"
            ></i>
            <Modal
                isOpen={modal_standard}
                size="md"
                toggle={() => {
                    tog_standard()
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                        Créer une notification
                    </h5>
                    <i className="bx bx-x"
                        onClick={() => setmodal_standard(false)}
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{ cursor: "pointer", fontSize: "1.3rem" }}
                    />
                </div>
                <div className="modal-body">
                    <div className="table-rep-plugin">
                        <div
                            className=" mb-0"
                            data-pattern="priority-columns"
                        >
                            <Table
                                className="table table-striped table-bordered"
                            >
                                <Thead>
                                    <Tr>
                                        <Th>Nom</Th>
                                        <Th>Description</Th>
                                        <Th>Status</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td>
                                            visite(s) non réalisée(s)
                                        </Td>
                                        <Td>
                                            Retourne la liste des visite non réalisée par utilisateurs
                                        </Td>
                                        <Td>h
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default NotifStandard