import React, { useEffect, useState } from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';

const Question = (props) => {

    const { question, index, deleteQuestion } = props
    const [q, setq] = useState(question);

    return (
        <Row>
            <Col xl={3} lg={3}>
                <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                >
                    Question
                </label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="question..."
                    value={question.name}
                    onChange={(e) => { setq({ ...q, name: e.target.value }); question.name = e.target.value }}
                />
            </Col>
            <Col xl={2} lg={2}>
                <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                >
                    Coéfficient
                </label>
                <input
                    className="form-control"
                    type="number"
                    placeholder="coéfficient..."
                    value={question.coef}
                    onChange={(e) => { setq({ ...q, coef: parseInt(e.target.value) }); question.coef = parseInt(e.target.value) }}
                />
            </Col>
            <Col xl={2} lg={2}>
                <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                >
                    Obligatoire
                </label>
                <div className="form-check form-switch form-switch-md mb-3" >
                    <input
                        type="checkbox"
                        style={{ cursor: "pointer", marginTop: "0.5rem" }}
                        className="form-check-input"
                        id="enabletooltip"
                        defaultChecked={question?.required}
                        onChange={(e) => { question.required = e.target.checked }}
                    />
                    <UncontrolledTooltip placement="top" target="enabletooltip">
                        change status
                    </UncontrolledTooltip>
                </div>
            </Col>
            <Col xl={2} lg={2}>
                <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                >
                    Photos
                </label>
                <div className="form-check form-switch form-switch-md mb-3" >
                    <input
                        type="checkbox"
                        style={{ cursor: "pointer", marginTop: "0.5rem" }}
                        className="form-check-input"
                        id="enabletooltip"
                        defaultChecked={question?.images}
                        onChange={(e) => { question.images = e.target.checked }}
                    />
                    <UncontrolledTooltip placement="top" target="enabletooltip">
                        change status
                    </UncontrolledTooltip>
                </div>
            </Col>
            <Col xl={2} lg={2}>
                <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                >
                    Photos obligatoires
                </label>
                <div className="form-check form-switch form-switch-md mb-3" >
                    <input
                        type="checkbox"
                        style={{ cursor: "pointer", marginTop: "0.5rem" }}
                        className="form-check-input"
                        id="enabletooltip"
                        defaultChecked={question?.imagesRequired}
                        onChange={(e) => { question.imagesRequired = e.target.checked }}
                    />
                    <UncontrolledTooltip placement="top" target="enabletooltip">
                        change status
                    </UncontrolledTooltip>
                </div>
            </Col>
            <Col xl={1} lg={1}>
                <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                >
                    Supprimer
                </label>
                <i
                    className="bx bx-x"
                    style={{ color: "red", fontSize: "2rem", marginLeft: "1rem", cursor: "pointer" }}
                    onClick={() => { deleteQuestion(index) }}
                />
            </Col>
        </Row>
    )
}
export default Question