import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Tbody, Thead, Tr, Th, Td } from "react-super-responsive-table"
import { Modal } from 'reactstrap';
import { getSurveysAsync } from 'store/survey/actions';

function SelectSurvey(props) {

    const { storeId, visitId } = props
    const dispatch = useDispatch();
    const [modal_standard, setmodal_standard] = useState(false)
    const [surveys, setSurveys] = useState([])

    const getSurveys = async () => {
        setSurveys(dispatch(await getSurveysAsync()).payload.surveys)
    }

    const tog_standard = () => {
        setmodal_standard(!modal_standard)
    }

    useEffect(async () => {
        await getSurveys()
    }, []);

    return (
        <>
            <Link style={{ marginLeft: "1.5rem", color: "black" }} to="#" onClick={() => { tog_standard() }}>
                <i className="bx bx-edit text-success me-1" />
                Questionnaires
            </Link>
            <Modal
                isOpen={modal_standard}
                toggle={() => { tog_standard() }}
                size='md'
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                        Questionnaires
                    </h5>
                    <button
                        type="button"
                        onClick={() => setmodal_standard(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                    </button>
                </div>
                <div className="modal-body">
                    <div className="table-rep-plugin" style={{ marginTop: "2rem" }}>
                        <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                        >
                            <Table
                                id="mydatatable"
                                className="table table-striped table-bordered"
                            >
                                <Thead>
                                    <Tr>
                                        <Th>Questionnaire</Th>
                                        <Th>Action</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {surveys.map((survey, index) => (
                                        <Tr key={index}>
                                            <Td> {survey.name} </Td>
                                            <Td>
                                                <Link to={"surveyResponse/" + survey.id + "/" + storeId + "/" + visitId} target="_blank">selectionner ...</Link>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default SelectSurvey;