import React from 'react'
import { Label, Row, Col } from 'reactstrap';

function NotifScore(props) {

    const { setNotification, notification } = props

    return (
        <Row>
            <Col>
                <Label style={{ marginTop: "1rem" }}>Score</Label>
                <input
                    className="form-control"
                    type="number"
                    placeholder="score..."
                    value={notification.condition_score}
                    onChange={(e) => { setNotification({ ...notification, condition_score: e.target.value }); }}
                />
            </Col>
            <Col>
                <Label style={{ marginTop: "1rem" }}>Condition</Label>
                <select
                    className="form-control"
                    onChange={(e) => { setNotification({ ...notification, condition_operator: e.target.value }); }}
                >
                    <option>selectionner...</option>
                    <option value={"inférieur à"}>{"inférieur à " + notification.condition_score}</option>
                    <option value={"supérieur à"}>{"supérieur à " + notification.condition_score}</option>
                    <option value={"égale à"}>{"égale à" + notification.condition_score}</option>
                </select>
            </Col>
        </Row>
    )
}

export default NotifScore