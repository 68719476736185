import React, { useState } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { Collapse } from 'reactstrap';

const SubCategoryPart = (props) => {

    const { name, subCategoryAvg, bySubCategory, totalCoef } = props
    const [col1, setCol1] = useState(true)

    function groupByQuestion(items) {
        return items.reduce((acc, curr) => {
            if (curr) {
                const { name } = curr?.question;
                const currentItems = acc[name];
                return {
                    ...acc,
                    [name]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;
        }, {});
    }

    return (
        <div className="accordion" id="accordion">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className={+ col1 ? ("accordion-button fw-medium collapsed") : ("accordion-button fw-medium")} type="button" onClick={() => { setCol1(!col1) }} style={{ cursor: "pointer" }}>
                        {name}
                    </button>
                </h2>
                <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">

                        <div className="text-center">
                            <h6>{"Moyenne : " + parseFloat(subCategoryAvg / totalCoef).toFixed(2)}</h6>
                        </div>
                        <div className="table-rep-plugin" style={{ marginTop: "2rem" }}>
                            <div
                                className="table-responsive mb-0"
                                data-pattern="priority-columns"
                            >
                                <Table
                                    id="mydatatable"
                                    className="table table-striped table-bordered"
                                >
                                    <Thead>
                                        <Tr>
                                            <Th>Question</Th>
                                            <Th style={{ width: "15%" }}>Moyenne</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {Object.keys(groupByQuestion(bySubCategory)).map((name, i) => {
                                            let questionAvg = 0
                                            let totalCoef = 0
                                            Object.values(groupByQuestion(bySubCategory))[i].forEach((res) => {
                                                questionAvg = questionAvg + (res?.rate * res?.question?.coef)
                                                totalCoef = totalCoef + res?.question?.coef
                                            })
                                            const byQuestion = Object.values(groupByQuestion(bySubCategory))[i]
                                            return (
                                                <Tr key={i}>
                                                    <Td>{name}</Td>
                                                    <Td style={{ width: "15%" }}>{parseFloat(questionAvg / totalCoef).toFixed(2)}</Td>
                                                </Tr>
                                            )
                                        })}
                                    </Tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    )
}

export default SubCategoryPart;