import React, { useState } from 'react';
import { Col, Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import Question from './Question';

const Subcategory = (props) => {

    const { subcategory, deleteSubCategory, index } = props
    const [col1, setCol1] = useState(true)
    const [questions, setQuestions] = useState([])
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [sc, setsc] = useState(subcategory)

    const newQuestion = () => {
        setQuestions([...questions, { name: "", coef: 1, required: true, images: true, imagesRequired: true }])
        subcategory.questions.push({ name: "", coef: 1, required: true, images: true, imagesRequired: true })
    }

    const deleteQuestion = (i) => {
        setQuestions(questions.filter((q, index) => i !== index))
        subcategory.questions = subcategory.questions.filter((q, index) => i !== index)
    }

    return (
        <div className="accordion" id="accordion">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className={+ col1 ? ("accordion-button fw-medium collapsed") : ("accordion-button fw-medium")} type="button" onClick={() => { setCol1(!col1) }} style={{ cursor: "pointer" }}>
                        Sous catégorie
                    </button>
                </h2>
                <Collapse isOpen={col1} className="accordion-collapse">

                    <div className="accordion-body">
                        <Row style={{ marginTop: "-2rem", marginBottom: "2rem" }}>
                            <Col />
                            <Col>
                                <label
                                    htmlFor="example-text-input"
                                    className="col-form-label"
                                >
                                    Sous-catégorie
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Nom de la sous-catégorie"
                                    value={subcategory.name}
                                    onChange={(e) => { setsc(e.target.value); subcategory.name = e.target.value }}
                                />
                            </Col>
                            <Col>
                                <Dropdown
                                    isOpen={btnprimary1}
                                    toggle={() => setBtnprimary1(!btnprimary1)}
                                    style={{ marginTop: "2.3rem" }}
                                >
                                    <DropdownToggle tag="button" className="btn btn-primary">
                                        Actions <i className="mdi mdi-chevron-down" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem
                                            onClick={() => { newQuestion() }}
                                        >
                                            <i className="bx bx-plus" /> question
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => { deleteSubCategory(index) }}
                                        >
                                            <i className="bx bx-x" />Supprimer sous-catégorie
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </Row>
                        {subcategory.questions.map((question, index) => (
                            <Question
                                key={index}
                                index={index}
                                question={question}
                                deleteQuestion={deleteQuestion}
                            />
                        ))}
                    </div>
                </Collapse>
            </div>
        </div>
    )
}
export default Subcategory