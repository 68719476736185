import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { CardImg, } from 'reactstrap';
import 'react-image-lightbox/style.css';

const VisitGallery = (props) => {

    const { pictures } = props
    const [photoIndex, setphotoIndex] = useState(0)
    const [isGalleryZoom, setisGalleryZoom] = useState(false)

    return (
        <div>
            {pictures.map((image, index) => (
                <CardImg
                    key={index}
                    src={image?.path}
                    alt="Skote"
                    style={{ cursor: "pointer", marginLeft: "1rem", marginBottom: "0.5rem" }}
                    className="rounded avatar-sm"
                    onClick={() => { setisGalleryZoom(true); setphotoIndex(index) }}
                />
            ))}
            {isGalleryZoom &&
                <Lightbox
                    mainSrc={pictures[photoIndex]?.path}
                    nextSrc={pictures[(photoIndex + 1) % pictures?.length]?.path}
                    prevSrc={pictures[(photoIndex + pictures?.length - 1) % pictures.length]?.path}
                    onCloseRequest={() => {
                        setisGalleryZoom(false)
                    }}
                    onMovePrevRequest={() => {
                        setphotoIndex((photoIndex + pictures?.length - 1) % pictures?.length)
                    }}
                    onMoveNextRequest={() => {
                        setphotoIndex((photoIndex + 1) % pictures?.length)
                    }}
                />
            }
        </div>
    )
}
export default VisitGallery;