import { element } from 'prop-types';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import ApexRadial from './ApexRadial';

function TodaySupervisorPerfDetail(props) {

    const { visits } = props
    const [data, setData] = useState([])



    function groupByDay(items) {
        return items.reduce((acc, curr) => {
            if (curr) {
                const { day } = curr;
                const currentItems = acc[day];
                return {
                    ...acc,
                    [day]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;
        }, {});
    }

    useEffect(() => {
        setData(Object.values(groupByDay(visits)))
    }, [])


    return (
        <Row onClick={() => { console.log(data); }}>
            <Col xl={3} lg={3} md={4} xs={12}>
                <h6 style={{ marginTop: "5rem" }}>{visits[0].user.first_name + " " + visits[0].user.last_name}</h6>
            </Col>
            {data.map((elements, index) => {
                let realisedVisits = 0
                let plannedVisits = elements.length
                elements.forEach(element => {
                    realisedVisits = realisedVisits + element.surveyResponses.length
                });
                return (
                    <Col key={index} xl={3} lg={3} md={4} xs={12}>
                        <ApexRadial
                            plannedVisits={plannedVisits}
                            realisedVisits={realisedVisits}
                            day={new Date(elements[0].day).getDate()}
                            month={new Date(elements[0].day).getMonth()+1}
                        />
                    </Col>
                )
            })}
        </Row>
    )
}

export default TodaySupervisorPerfDetail