import React, { useEffect } from 'react'
import { useState } from 'react'
import { Card, CardHeader, Col, Row } from 'reactstrap'
import { BarChart, XAxis, YAxis, Tooltip, ResponsiveContainer, Bar } from 'recharts';
import BarDetail from './BarDetail';

function Chart(props) {

    const { filtredData, selectedType } = props
    const [data, setData] = useState([])
    const [payload, setPayload] = useState([])
    const [modal_standard, setmodal_standard] = useState(false)

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ background: "white" }}>
                    <p className="name">{`${selectedType.slice(0, selectedType.length - 1)} : ${label}`}</p>
                    <p className="avg">{` Moyenne : ${payload[0]?.payload?.avg}`}</p>
                </div>
            );
        }
        return null;
    };

    function groupBy(items) {
        return items.reduce((acc, curr) => {
            if (curr.store && selectedType === "Magasins") {
                const { name } = curr.store;
                const currentItems = acc[name];

                return {
                    ...acc,
                    [name]: currentItems ? [...currentItems, curr] : [curr]
                };
            } else if (curr.store && selectedType === "Villes") {
                const { governorate } = curr.store;
                const currentItems = acc[governorate];

                return {
                    ...acc,
                    [governorate]: currentItems ? [...currentItems, curr] : [curr]
                };
            } else if (curr.user && selectedType === "Superviseurs") {
                const { username } = curr.user;
                const currentItems = acc[username];

                return {
                    ...acc,
                    [username]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;
        }, {});
    }

    useEffect(() => {
        setData([])
        Object.values(groupBy(filtredData)).forEach((visits, index) => {
            let avg = 0
            visits.forEach(visit => {
                avg = (avg + visit.average)
            });
            setData(data => [
                ...data,
                { name: Object.keys(groupBy(filtredData))[index], avg: parseFloat(avg / visits.length).toFixed(2), visits: visits }
            ])
        })
    }, [filtredData, selectedType])

    return (
        <Row>
            <Col>
                <Card
                    outline
                    color="primary"
                    className="border"
                >
                    <CardHeader className="bg-transparent">
                        <h4>Moyennes par : {" " + selectedType}</h4>
                    </CardHeader>
                    {data.length > 0 &&
                        <div style={{ width: '100%', height: data.length * 100 }}>
                            <ResponsiveContainer>
                                <BarChart
                                    data={data}
                                    layout="vertical"
                                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                >
                                    <XAxis type="number" domain={[0, 10]} ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} />
                                    <YAxis type="category" dataKey="name" />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Bar dataKey="avg" onClick={(e) => { setmodal_standard(!modal_standard); setPayload(e.payload); }} fill="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    }
                    <BarDetail
                        modal_standard={modal_standard}
                        setmodal_standard={setmodal_standard}
                        payload={payload}
                        selectedType={selectedType}
                    />
                </Card>
            </Col>
        </Row>
    )
}

export default Chart