import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import QuestionPart from './QuestionPart';

const SubCategoryPart = (props) => {

    const { subCategory } = props
    const [col1, setCol1] = useState(true)

    return (
        <div className="accordion" id="accordion">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className={+ col1 ? ("accordion-button fw-medium collapsed") : ("accordion-button fw-medium")} type="button" onClick={() => { setCol1(!col1) }} style={{ cursor: "pointer" }}>
                        {subCategory.name}
                    </button>
                </h2>
                <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                        {subCategory.questions?.map((question, index) => (
                            <QuestionPart
                                key={index}
                                question={question}
                            />
                        ))}
                    </div>
                </Collapse>
            </div>
        </div>
    )
}

export default SubCategoryPart;
