import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal, UncontrolledTooltip, Spinner, Card, CardHeader, CardBody, Row, Col } from 'reactstrap'
import InfiniteScroll from "react-infinite-scroll-component";
import { getVisitsByStore } from 'store/visit/services';
import ReportDetail from './modalsComponents/ReportDetail';
import ResponsesDetail from './modalsComponents/ResponsesDetail';

const StoreHistory = (props) => {

    const { storeId } = props
    const [items, setItems] = useState([])
    const [modal_fullscreen, setmodal_fullscreen] = useState(false)
    const [limit, setLimit] = useState(8)
    const [offset, setOffset] = useState(0)

    function tog_fullscreen() {
        setmodal_fullscreen(!modal_fullscreen)
        if (!modal_fullscreen) {
            setOffset(0)
            setItems([])
            getVisitsByStore(storeId, limit, offset)
                .then((data) => {
                    setItems(items.concat(data));
                })
        }
    }

    useEffect(() => {
        getVisitsByStore(storeId, limit, offset)
            .then((data) => {
                setTimeout(() => {
                    setItems(items.concat(data));
                }, 1500);
            })
    }, [limit, offset])

    return (
        <>
            <Link
                to="#"
                onClick={() => { tog_fullscreen() }}
                style={{ fontSize: "1.3rem" }}
            >
                <i className="bx bx-list-ul" id="history" />
                <UncontrolledTooltip placement="top" target="history">
                    Historique
                </UncontrolledTooltip>
            </Link>
            <Modal
                size="xl"
                scrollable
                isOpen={modal_fullscreen}
                zIndex={1001}
                toggle={() => { tog_fullscreen() }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                    >
                        Historique
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_fullscreen(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" id="scrollableDiv">
                    <InfiniteScroll
                        dataLength={items.length}
                        next={() => { setOffset(offset + limit) }}
                        hasMore={true}
                        loader={
                            <div style={{ marginTop: "2rem", width: "20%", margin: "auto" }}>
                                {Array(5).fill().map((element, index) => (
                                    <Spinner key={index} type="grow" className="ms-2" color="secondary" />
                                ))}
                            </div>
                        }
                        scrollableTarget="scrollableDiv"
                    >
                        {items.map((visit, index) => {
                            if (visit.reports.length > 0 || visit.surveyResponses.length > 0) {
                                return (
                                    <Card key={index} outline color="success" className="border">
                                        <CardHeader className="bg-transparent">
                                            <h5 className="my-0 text-success">
                                                <i className="bx bx-calendar me-3" />{visit.day.slice(0, 10)}
                                            </h5>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                {visit.surveyResponses.map((surveyResponse, index) => (
                                                    <Col xl="3" lg="3" key={index}>
                                                        <ResponsesDetail
                                                            response={surveyResponse}
                                                        />
                                                    </Col>
                                                ))}
                                                {visit.reports.map((report, index) => {
                                                    return (
                                                        <Col xl="3" lg="3" key={index}>
                                                            <ReportDetail
                                                                report={report}
                                                            />
                                                        </Col>
                                                    )
                                                })}
                                            </Row>

                                        </CardBody>
                                    </Card>
                                )
                            }
                        })}
                    </InfiniteScroll>
                </div>
            </Modal>
        </>
    )
}

export default StoreHistory