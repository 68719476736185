import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import TodaySupervisorPerfDetail from './TodaySupervisorPerfDetail';

function TodaySupervisorPerformance(props) {

    const { visits } = props
    const [data, setData] = useState([])
    const [dataByDate, setDataByDate] = useState([])

    function groupByDay(items) {
        return items.reduce((acc, curr) => {
            if (curr) {
                const { day } = curr;
                const currentItems = acc[day];
                return {
                    ...acc,
                    [day]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;
        }, {});
    }

    function groupBySupervisor(items) {
        return items.reduce((acc, curr) => {
            if (curr) {
                const { username } = curr?.user;
                const currentItems = acc[username];
                return {
                    ...acc,
                    [username]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;
        }, {});
    }

    useEffect(() => {
        Object.values(groupByDay(visits)).sort((a, b) => { return (new Date(b[0].day) - new Date(a[0].day)) }).slice(0, 3)
            .forEach((visit) => {
                visit.forEach(element => {
                    setDataByDate(dataByDate => [...dataByDate, element])
                });
            })
    }, [])

    useEffect(() => {
        setData(Object.values(groupBySupervisor(dataByDate)))
    }, [dataByDate])



    return (
        <Card style={{ overflowY: "auto", height: "38rem" }}>
            <CardBody>
                <CardTitle className="mb-4">Taux de réalisation par superviseur</CardTitle>
                <div>
                    {data.map((visits, index) => {

                        return (
                            <TodaySupervisorPerfDetail
                                key={index}
                                index={index}
                                visits={visits}
                            />
                        )
                    })}
                </div>
            </CardBody>
        </Card>
    )
}

export default TodaySupervisorPerformance