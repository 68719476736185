import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select";
import { getUsersByRole } from "store/user/services";
import WeekComponent from "./weekComponents";
import MonthComponent from "./monthComponents";
import { useSelector } from "react-redux";

const PlanningControl = () => {

    const connectedUser = useSelector(state => state.User?.user)
    const userRole = useSelector(state => state.User?.user?.role?.name)
    const [merchandisers] = useState([])
    const [selectedMerchandiser, setSelectedMerchandiser] = useState(null)
    const [calendarType, setCalendarType] = useState("month")

    useEffect(() => {
        getUsersByRole("superviseur").then((data) => {
            data.forEach(element => {
                merchandisers.push({ value: element.id, label: element.first_name + " " + element.last_name })
            });
        })
    }, [])

    useEffect(() => {
        if (userRole === "superviseur") {
            setSelectedMerchandiser(connectedUser.id);
        }
    }, [connectedUser])

    return (
        <div className="page-content">
            <MetaTags>
                <title>Planning review</title>
            </MetaTags>
            <Container fluid={true}>
                <Breadcrumbs  title="Planning" breadcrumbItem="Suivi planning" />
                <Card>
                    <CardBody>
                        {userRole && (userRole === "admin" || userRole === "super_admin") &&
                            <Row style={{ width: "50%", margin: "auto" }}>
                                <Col>
                                    <div className="mb-3">
                                        <Label>Choose a superviseur</Label>
                                        <Select
                                            placeholder="Choose a superviseur..."
                                            options={merchandisers}
                                            classNamePrefix="select2-selection"
                                            onChange={(e) => { setSelectedMerchandiser(e.value); }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        }
                        {selectedMerchandiser ? (
                            <>
                                {calendarType === "week" ? (
                                    <WeekComponent
                                        selectedMerchandiser={selectedMerchandiser}
                                        setCalendarType={setCalendarType}
                                    />
                                ) : (
                                    <MonthComponent
                                        selectedMerchandiser={selectedMerchandiser}
                                        setCalendarType={setCalendarType}
                                    />
                                )}
                            </>
                        ) : (
                            <h1 style={{ width: '60%', margin: 'auto', marginTop: '12rem', marginBottom: '29rem' }}>Selectionner un superviseur pour visualiser le planning</h1>
                        )
                        }
                    </CardBody>
                </Card>
            </Container >
        </div >
    )
}
export default PlanningControl