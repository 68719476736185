import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import DashboardAdmin from './DashboardAdmin';
import { useSelector } from 'react-redux';
import DashboardSupervisor from './DashboardSupervisor';
import { getVisits } from 'store/dashboard/services';
import { socket } from 'helpers/consts';

const Dashboard = props => {

  const connectedUser = useSelector(state => state.User.user)
  const [visits, setVisits] = useState([])

  const getVisitList = () => {
    getVisits().then((data) => {
      setVisits(data)
    })
  }

  useEffect(() => {
    getVisitList()
  }, [connectedUser])

  useEffect(() => {
    socket.on('dashboardCron', (msg) => {
      getVisitList()
    });
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tableau de bord</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={props.t("Tableau de bord")} breadcrumbItem={props.t("Tableau de bord")} />
          {connectedUser?.role?.name === "superviseur" ? (
            <DashboardSupervisor
              connectedUser={connectedUser}
              visits={visits.filter((visit) => visit.userId === connectedUser.id)}
            />
          ) : (
            <DashboardAdmin
              connectedUser={connectedUser}
              visits={visits}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)