import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, Card, CardBody, CardTitle, Label, Progress } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import $ from 'jquery';
import { getSupervisorPerformance } from "store/charts/services";
import RowPerformance from "./components/RowPerformance";
import Select from "react-select";
import { governorates } from "shared/TunisiaGovernorates";
import { useDispatch } from "react-redux";
import { getSurveysAsync } from "store/survey/actions";
import { getStoresAsync } from "store/pos/actions";
import { getUsersByRole } from "store/user/services";

function SupervisorAnalyse() {

    const dispatch = useDispatch()
    const [from, setFrom] = useState(null)
    const [to, setTo] = useState(null)
    const [data, setData] = useState([])
    const [stores] = useState([])
    const [surveys] = useState([])
    const [merchandisers] = useState([])
    const storeOptions = [{ options: stores }]
    const governorateOptions = [{ options: governorates }]
    const merchandisersOptions = [{ options: merchandisers }]
    const [selectedGovernorates, setSelectedGovernorates] = useState(null)
    const [selectedStores, setSelectedStores] = useState(null)
    const [selectedSurveys, setSelectedSurveys] = useState(null)
    const [selectedMerchandisers, setSelectedMerchandisers] = useState(null)

    function groupBySupervisor(items) {
        return items.reduce((acc, curr) => {
            if (curr) {
                const { username } = curr?.user;
                const currentItems = acc[username];
                return {
                    ...acc,
                    [username]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;
        }, {});
    }

    const getData = () => {
        getSupervisorPerformance(
            from,
            to,
            !selectedStores ? (stores) : (selectedStores),
            !selectedSurveys ? (surveys) : (selectedSurveys),
            !selectedMerchandisers ? (merchandisers) : (selectedMerchandisers),
            !selectedGovernorates ? (governorates) : (selectedGovernorates)
        )
            .then((data) => {
                setData(groupBySupervisor(data))
                $('#mydatatable').DataTable()
            })
    }

    useEffect(async () => {
        dispatch(await getSurveysAsync()).payload.surveys.forEach(element => {
            surveys.push({ value: element.id, label: element.name })
        });

        dispatch(await getStoresAsync()).payload.stores.forEach(element => {
            stores.push({ value: element.id, label: element.name + " | " + element.address + ", " + element.postal_code + " - " + element.governorate })
        });

        getUsersByRole("superviseur").then((data) => {
            data.forEach(element => {
                merchandisers.push({ value: element.id, label: element.first_name + " " + element.last_name })
            });
        })
    }, [])

    const styles = {
        control: (provided, state) => ({
            backgroundColor: "#2a3042",
        }),
        placeholder: (provided, state) => ({
            backgroundColor: "#2a3042"
        }),
    };

    return (
        <div className="page-content">
            <MetaTags>
                <title>Analyses superviseur</title>
            </MetaTags>
            <div className="container-fluid">
                <Breadcrumbs title="Analyses" breadcrumbItem="Analyses superviseur" />
                <Card>
                    <CardBody>
                        <CardTitle>Analyses superviseur</CardTitle>
                        <div style={{ marginBottom: "3rem", marginTop: "2rem" }}>
                            <Row>
                                <Col lg={2} md={6}>
                                    <Label>Villes</Label>
                                    <Select
                                        placeholder="Villes..."
                                        value={selectedGovernorates}
                                        options={governorateOptions}
                                        isMulti={true}
                                        classNamePrefix="select2-selection"
                                        /* styles={styles} */
                                        onChange={(e) => { setSelectedGovernorates(e); }}
                                    />
                                </Col>
                                <Col lg={2} md={6}>
                                    <Label>Magasins</Label>
                                    <Select
                                        placeholder="Magasins..."
                                        value={selectedStores}
                                        isMulti={true}
                                        onChange={(e) => { setSelectedStores(e) }}
                                        options={storeOptions}
                                        /* styles={styles} */
                                        classNamePrefix="select2-selection"
                                    />
                                </Col>
                                <Col lg={2} md={6}>
                                    <Label>Questionnaire</Label>
                                    <Select
                                        placeholder="Questionnaires..."
                                        options={surveys}
                                        isMulti={true}
                                        value={selectedSurveys}
                                        classNamePrefix="select2-selection"
                                        /* styles={styles} */
                                        onChange={(e) => { setSelectedSurveys(e) }}
                                    />
                                </Col>
                                <Col lg={2} md={6}>
                                    <Label>Superviseurs</Label>
                                    <Select
                                        placeholder="Superviseurs..."
                                        value={selectedMerchandisers}
                                        isMulti={true}
                                        options={merchandisersOptions}
                                        classNamePrefix="select2-selection"
                                        /* styles={styles} */
                                        onChange={(e) => { setSelectedMerchandisers(e) }}
                                    />
                                </Col>
                                <Col lg={2} md={6}>
                                    <Label>
                                        Date début
                                        <span style={{ fontSize: "15px", color: "red" }}>*</span>
                                    </Label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="example-date-input"
                                        onChange={(e) => { setFrom(e.target.value); }}
                                    />
                                </Col>
                                <Col lg={2} md={6}>
                                    <Label>
                                        Date fin
                                        <span style={{ fontSize: "15px", color: "red" }}>*</span>
                                    </Label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="example-date-input"
                                        onChange={(e) => { setTo(e.target.value); }}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1rem" }}>
                                <div className="text-center">
                                    <Col>
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary"
                                            style={{ marginTop: "1rem" }}
                                            onClick={() => { getData() }}
                                        >
                                            Valider
                                        </button>
                                    </Col>
                                </div>
                            </Row>
                        </div>
                        {data.length !== 0 &&
                            <div className="table-rep-plugin" style={{ marginTop: "2rem" }}>
                                <div
                                    className="table-responsive mb-0"
                                    data-pattern="priority-columns"
                                >
                                    <Table
                                        id="mydatatable"
                                        className="table table-striped table-bordered"
                                    >
                                        <Thead>
                                            <Tr>
                                                <Th>Superviseur</Th>
                                                <Th>Moyenne retard</Th>
                                                <Th>Moyenne dernier pointage</Th>
                                                <Th>Visites planifiées</Th>
                                                <Th>Visites non planifiées</Th>
                                                <Th>Visites réalisées</Th>
                                                <Th>Questionnaires réalisés</Th>
                                                <Th>Moyenne questionnaires</Th>
                                                <Th>Nombre photos</Th>
                                                <Th>Performance</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {Object.values(data).map((visits, index) => {
                                                return (
                                                    <RowPerformance
                                                        key={index}
                                                        visits={visits}
                                                    />
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                </div>
                            </div>
                        }
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default SupervisorAnalyse