import { api } from "helpers/consts";

/**
 * 
 * @returns ReportSubject list
 */
export const getReportSubjects = async () => {
    const res = await api.get(`/reportSubject`);
    return res.data.data;
}

/**
 * 
 * @param {ReportSubject} reportSubject 
 * @description create/update new ReportSubject 
 */
export const upsertReportSubject = async (reportSubject) => {
    const res = await api.post(`/reportSubject`, reportSubject)
    return res
}