import React from 'react';
import { Col, Row } from 'reactstrap';

const QuestionPart = (props) => {

    const { question } = props

    return (
        <Row>
            <Col xl={4} lg={4}>
                <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                >
                    Question
                </label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="question..."
                    defaultValue={question.name}
                    readOnly
                />
            </Col>
            <Col xl={2} lg={2}>
                <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                >
                    Coéfficient
                </label>
                <input
                    className="form-control"
                    type="number"
                    placeholder="coéfficient..."
                    defaultValue={question.coef}
                    readOnly
                />
            </Col>
            <Col xl={2} lg={2}>
                <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                >
                    Obligatoire
                </label>
                <br />
                {question.required ? (
                    <i className="bx bx-comment-check" style={{color: "green", fontSize: "2rem"}} />
                ) : (
                    <i className="bx bx-x-circle" style={{color: "red", fontSize: "2rem"}} />
                )}
            </Col>
            <Col xl={2} lg={2}>
                <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                >
                    Photos
                </label>
                <br />
                {question.images ? (
                    <i className="bx bx-comment-check" style={{color: "green", fontSize: "2rem"}} />
                ) : (
                    <i className="bx bx-x-circle" style={{color: "red", fontSize: "2rem"}} />
                )}
            </Col>
            <Col xl={2} lg={2}>
                <label
                    htmlFor="example-text-input"
                    className="col-form-label"
                >
                    Photos obligatoires
                </label>
                {question.imagesRequired ? (
                    <i className="bx bx-comment-check" style={{color: "green", fontSize: "2rem"}} />
                ) : (
                    <i className="bx bx-x-circle" style={{color: "red", fontSize: "2rem"}} />
                )}
            </Col>
        </Row>
    )
}

export default QuestionPart;
