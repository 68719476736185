import React from 'react'
import { Col, Label, Row } from 'reactstrap';
import Select from "react-select";

function SharedColumns(props) {

    const { setNotification, notification, storeOptions, userOptions, setSelectedStores, setSelectedUsers } = props

    return (
        <>
            <Row>
                <Col>
                    <Label style={{ marginTop: "1rem" }}>Utilisateurs concernés</Label>
                    <Select
                        placeholder="Utilisateurs..."
                        options={userOptions}
                        isMulti={true}
                        classNamePrefix="select2-selection"
                        onChange={(e) => { setSelectedUsers(e); }}
                    />
                </Col>
                <Col>
                    <Label style={{ marginTop: "1rem" }}>Magasins concernés</Label>
                    <Select
                        placeholder="Magasins..."
                        options={storeOptions}
                        isMulti={true}
                        classNamePrefix="select2-selection"
                        onChange={(e) => { setSelectedStores(e); }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xl="6" lg="6">
                    <Label style={{ marginTop: "1rem" }}>Extra</Label>
                    <Row>
                        <Col>
                            <div className="form-check form-switch form-switch-md mb-3">
                                <input
                                    type="checkbox"
                                    style={{ cursor: "pointer" }}
                                    className="form-check-input"
                                    checked={notification.email}
                                    onChange={() => { setNotification({ ...notification, email: !notification.email }); }}
                                />
                                <Label>Email</Label>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-check form-switch form-switch-md mb-3">
                                <input
                                    type="checkbox"
                                    style={{ cursor: "pointer" }}
                                    className="form-check-input"
                                    checked={notification.sms}
                                    onChange={() => { setNotification({ ...notification, sms: !notification.sms }); }}
                                />
                                <Label>SMS</Label>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </>
    )
}

export default SharedColumns