import React from 'react'
import { Col, InputGroup, Label, Row } from 'reactstrap'
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

function NotifPointage(props) {

  const { setNotification, notification } = props

  return (
    <Row>
      <Col>
        <Label style={{ marginTop: "1rem" }}>Type de pointage</Label>
        <select
          className="form-control"
          onChange={(e) => { setNotification({ ...notification, pointage_type: e.target.value }); }}
        >
          <option value={"entrée"}>{"Ponitage entrée"}</option>
          <option value={"sortie"}>{"Ponitage sortie"}</option>
        </select>
      </Col>
      <Col>
        <Label style={{ marginTop: "1rem" }}>Heure de pointage</Label>
        <InputGroup>
          <Flatpickr
            className="form-control d-block"
            placeholder="Select time"
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",
              time_24hr: true
            }}
            onChange={(e) => { setNotification({ ...notification, pointage_hour: new Date(e[0]).getHours(), pointage_minute: new Date(e[0]).getMinutes() }); }}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <i className="mdi mdi-clock-outline" />
            </span>
          </div>
        </InputGroup>
      </Col>
    </Row>
  )
}

export default NotifPointage