import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css"
import { Card, CardFooter, CardImg, Col, Row } from 'reactstrap';

const VisitGallery = (props) => {

    const { pictures, setselectedFiles } = props
    const [photoIndex, setphotoIndex] = useState(0)
    const [isGalleryZoom, setisGalleryZoom] = useState(false)

    return (
        <div className="zoom-gallery" style={{ marginTop: "2.5rem" }}>
            <Row>
                {pictures.map((image, index) => (
                    <Col xl={2}>
                        <Card>
                            <CardImg
                                src={image?.preview}
                                alt="Skote"
                                style={{ cursor: "pointer", marginLeft: "1rem", marginBottom: "1rem" }}
                                className="rounded avatar-xl"
                                onClick={() => { setisGalleryZoom(true); setphotoIndex(index) }}
                            />
                            <CardFooter>
                                <button
                                    type="button"
                                    style={{ marginTop: "-1.5rem", width: "7.6rem", marginLeft: "-0.3rem" }}
                                    className="btn btn-danger waves-effect waves-light"
                                    onClick={() => { setselectedFiles(pictures.filter((q, i) => i !== index)); }}
                                >
                                    <i className="bx bx-block font-size-16 align-middle me-2"></i>{" "}
                                    Supprimer
                                </button>
                            </CardFooter>
                        </Card>
                    </Col>
                ))}
            </Row>
            {isGalleryZoom &&
                <Lightbox
                    mainSrc={pictures[photoIndex]?.preview}
                    nextSrc={pictures[(photoIndex + 1) % pictures?.length]?.preview}
                    prevSrc={pictures[(photoIndex + pictures?.length - 1) % pictures.length]?.preview}
                    onCloseRequest={() => {
                        setisGalleryZoom(false)
                    }}
                    onMovePrevRequest={() => {
                        setphotoIndex((photoIndex + pictures?.length - 1) % pictures?.length)
                    }}
                    onMoveNextRequest={() => {
                        setphotoIndex((photoIndex + 1) % pictures?.length)
                    }}
                />
            }
        </div>
    )
}
export default VisitGallery;