import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Auth from "./auth/reducer"
import User from "./user/reducer"
import Roles from "./roles-permissions/reducer"
import Stores from "./pos/reducer"
import Surveys from "./survey/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Auth,
  User,
  Roles,
  Stores,
  Surveys
})

export default rootReducer
