import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Label, Row } from "reactstrap"
import RatingTooltip from "react-rating-tooltip"
import MutipleImagesUpload from 'shared/MutipleImagesUpload';
import VisitGallery from './VisitGallery';

const Question = (props) => {

    const { question, responses, setResponses } = props
    const [rate, setRate] = useState(0)
    const [description, setDescription] = useState("")
    const [selectedFiles, setselectedFiles] = useState([]);
    const starStyle = {}

    useEffect(() => {
        setResponses(responses => [...responses, { questionId: question.id, question: question, rate: null, description: null, images: selectedFiles }])
    }, []);

    useEffect(() => {
        if (responses.length > 0) {
            responses[responses.findIndex((response) => response.questionId === question?.id)].images = selectedFiles
        }
    }, [responses, selectedFiles]);


    return (
        <Card
            outline
            color="primary"
            className="border"
        >
            <CardHeader className="bg-transparent">
                <h4>
                    Question : {question?.name} {question?.required && <span style={{ color: "red" }}>*</span>}
                </h4>
            </CardHeader>
            <CardBody>
                <Row style={{ marginBottom: "1rem" }}>
                    <Col xl={5}>
                        <div style={{ marginBottom: "2rem" }}>
                            <Label style={{ marginLeft: "42%", marginBottom: "1.5rem" }}>Note: {" " + rate}</Label>
                            <div style={{marginLeft: "-0.85rem"}}>
                            <RatingTooltip
                                max={10}
                                onChange={rate => {
                                    responses[responses.findIndex((response) => response.questionId === question?.id)].rate = rate
                                    setRate(rate)
                                }}
                                value={rate}
                                ActiveComponent={
                                    <i
                                        className="mdi mdi-star text-primary"
                                        style={starStyle}
                                    />
                                }
                                InActiveComponent={
                                    <i
                                        className="mdi mdi-star-outline text-muted"
                                        style={starStyle}
                                    />
                                }
                            />
                            </div>
                            
                        </div>
                    </Col>
                    <Col>
                        <Label htmlFor="formmessage">Description :</Label>
                        <Input
                            type="textarea"
                            placeholder="description..."
                            id="formmessage"
                            className="form-control"
                            rows="3"
                            value={responses[responses.findIndex((response) => response.questionId === question?.id)]?.description ? (
                                responses[responses.findIndex((response) => response.questionId === question?.id)].description
                            ) : (
                                description
                            )}
                            onChange={(e) => {
                                setDescription(e.target.value)
                                responses[responses.findIndex((response) => response.questionId === question?.id)].description = e.target.value
                            }}
                        />
                    </Col>
                </Row>
                {selectedFiles &&
                    <VisitGallery
                        pictures={selectedFiles}
                        setselectedFiles={setselectedFiles}
                    />
                }
                {question?.images === true &&
                    <Row>
                        <Label htmlFor="formmessage">
                            Photos :
                            {question?.imagesRequired === true &&
                                <span style={{ color: "red", fontSize: "20px" }}>{" *"}</span>
                            }
                        </Label>
                        <MutipleImagesUpload
                            selectedFiles={selectedFiles}
                            setselectedFiles={setselectedFiles}
                        />
                    </Row>
                }
            </CardBody>
        </Card>
    )
}

export default Question;
