import React, { useState } from 'react';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import { Collapse } from 'reactstrap';
import Question from './Question';

const SubCategoryPart = (props) => {

    const { subCategory, responses } = props
    const [col1, setCol1] = useState(true)

    return (
        <div className="accordion" id="accordion">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button className={+ col1 ? ("accordion-button fw-medium collapsed") : ("accordion-button fw-medium")} type="button" onClick={() => { setCol1(!col1) }} style={{ cursor: "pointer" }}>
                        {subCategory?.name}
                    </button>
                </h2>
                <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                        <div className="table-rep-plugin" style={{ marginTop: "2rem" }}>
                            <div
                                className="table-responsive mb-0"
                                data-pattern="priority-columns"
                            >
                                <Table
                                    id="mydatatable"
                                    className="table table-striped table-bordered"
                                >
                                    <Thead>
                                        <Tr>
                                            <Th>Question</Th>
                                            <Th>Note</Th>
                                            <Th>Description</Th>
                                            <Th>Photos</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {responses?.sort((a, b) => { return b.id - a.id }).map((response, index) => {
                                            return (
                                                <Question
                                                    key={index}
                                                    response={response}
                                                />
                                            )
                                        })}
                                    </Tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    )
}

export default SubCategoryPart;