import React, { useEffect } from 'react'
import { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Card, CardHeader, Col, Row, Collapse } from 'reactstrap'

function GeneralChart(props) {

    const { filtredData, selectedGovernorate } = props
    const [days, setDays] = useState([])
    const [data, setData] = useState([])

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
            events: {
                click: (event, chartContext, config) => { console.log(event); }
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "45%",
                endingShape: "rounded",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
        },
        colors: ["#34c38f", "#556ee6", "#f46a6a"],
        xaxis: {
            categories: days,
        },
        yaxis: {
            title: {
                text: "Moyenne",
            },
            min: 0,
            max: 10
        },
        responsive: [
            {
                breakpoint: 600,
                options: { chart: { toolbar: { show: !1 } }, legend: { show: !1 } },
            },
        ],
        grid: {
            borderColor: "#f1f1f1",
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                },
            },
        },
    }

    function groupByDate(items) {
        return items.reduce((acc, curr) => {
            if (curr) {
                const responseDay = curr.createdAt.slice(0, 10);
                const currentItems = acc[responseDay];

                return {
                    ...acc,
                    [responseDay]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;
        }, {});
    }

    function groupByName(items) {
        return items.reduce((acc, curr) => {
            if (curr.store?.name) {
                const { name } = curr.store;
                const currentItems = acc[name];

                return {
                    ...acc,
                    [name]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;
        }, {});
    }

    useEffect(() => {
        if (filtredData.length > 0) {
            setData([])
            setDays([])
            Object.keys(groupByDate(filtredData)).forEach((day) => {
                setDays(days => [...days, day])
            })
            Object.keys(groupByName(filtredData)).forEach((store) => {
                let arr = []
                Object.keys(groupByDate(filtredData)).forEach((day) => {
                    arr.push(filtredData.find((obj) => obj.createdAt.slice(0, 10) === day && obj.store.name === store)?.average || 0)
                })
                setData(data => [...data, { name: store, data: arr }])
            })
        }
    }, [filtredData])

    return (
        <Row>
            <Col lg={9}>
                <Card
                    outline
                    color="primary"
                    className="border"
                >
                    <CardHeader className="bg-transparent">
                        <h4>{selectedGovernorate}</h4>
                    </CardHeader>
                    <ReactApexChart
                        options={options}
                        series={data}
                        type="bar"
                        height={350}
                    />
                </Card>
            </Col>
        </Row>
    )
}

export default GeneralChart