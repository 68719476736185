import React, { useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { Card, CardBody, CardTitle, Container, Row } from 'reactstrap'
import PermissionsPart from './components/PermissionsPart';
import RolesPart from './components/RolesPart';
import Breadcrumbs from "components/Common/Breadcrumb"

export default function RolesPermissions() {

    const [selectedRole, setSelectedRole] = useState(null);

    return (
        <div className="page-content">
            <MetaTags>
                <title>Gestion des roles et permissions</title>
            </MetaTags>
            <Container fluid>
                <Breadcrumbs title="Paramétrages" breadcrumbItem="roles et permissions" />
                <Card>
                    <CardBody>
                        <CardTitle>Gestion des roles et permissions</CardTitle>
                        <Row style={{ marginTop: "3rem" }}>
                            <RolesPart selectedRole={selectedRole} setSelectedRole={setSelectedRole} />
                            <PermissionsPart selectedRole={selectedRole} />
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}
