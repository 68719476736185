import socketIOClient from "socket.io-client"
import axios from "axios";

export const API_URL = process.env.REACT_APP_API_URL;
export let api;

/**
 * @description config axios
 */
const token = localStorage.getItem('authUser'); // token from localStorage
api = axios.create({
  baseURL: API_URL,
  headers: {
    'Authorization': 'Bearer ' + token
  }
})


/**
 * socket client
 */
export const socket = socketIOClient(API_URL)