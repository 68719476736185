import React, { useState } from 'react';
import { Card, CardBody, CardFooter, Col, Modal, Row, Button, FormGroup } from 'reactstrap';
import ErrorAlert from 'shared/ErrorAlert';
import { upsertSurvey } from 'store/survey/services';
import CategoriePart from './CategoriePart';

const CreateSurvey = (props) => {

    const { getSurveys } = props
    const [modal_fullscreen, setmodal_fullscreen] = useState(false)
    const [survey, setSurvey] = useState({})
    const [categories, setCategories] = useState([])
    const [errorMessage, setErrorMessage] = useState(null)

    const newCategory = () => {
        setCategories([...categories, { name: "", subCategories: [] }])
    }

    const deleteCategory = (i) => {
        setCategories(categories.filter((q, index) => i !== index))
    }

    const createSurvey = () => {
        if (categories.length === 0 || categories[0].subCategories.length === 0 || categories[0].subCategories[0].questions.length === 0) {
            setErrorMessage("questionnaire incomplet!")
            setTimeout(async () => {
                setErrorMessage(null)
            }, 5000)
        } else {
            upsertSurvey({ survey: survey, categories: categories }).then(async () => {
                await getSurveys()
                tog_fullscreen()
            }).catch((error) => {
                setErrorMessage(error.response.data.message)
                setTimeout(async () => {
                    setErrorMessage(null)
                }, 5000)
            })
        }
    }

    function tog_fullscreen() {
        setmodal_fullscreen(!modal_fullscreen)
        if (modal_fullscreen) {
            setCategories([])
            setSurvey({ name: "" })
        }
    }

    return (
        <>
            <Col xl="2" sm="6" style={{ cursor: "pointer" }}>
                <Card style={{ color: "#556EE6", fontSize: "8rem" }} onClick={() => { tog_fullscreen() }} className="text-center">
                    <CardBody style={{ height: "15rem" }}>
                        <i className="bx bx-plus" style={{ fontSize: "6rem" }} />
                    </CardBody>
                    <CardFooter className="bg-transparent border-top">
                        <h5>Nouveau questionnaire</h5>
                    </CardFooter>
                </Card>
            </Col>
            <Modal
                size="xl"
                isOpen={modal_fullscreen}
                toggle={() => { tog_fullscreen() }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="exampleModalFullscreenLabel"
                    >
                        Nouveau questionnaire
                    </h5>
                    <button
                        onClick={() => { setmodal_fullscreen(false) }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                        style={{ marginLeft: "34%" }}
                    >
                        Nom
                    </label>
                    <Row>
                        <Col />
                        <Col>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Nom du questionnaire"
                                defaultValue={survey.name}
                                onChange={(e) => { setSurvey({ ...survey, name: e.target.value }) }}
                            />
                        </Col>
                        <Col>
                            <Button
                                color="primary"
                                outline
                                className="waves-effect waves-light"
                                onClick={() => { newCategory() }}
                            >
                                <i className="bx bx-plus" />
                                Catégorie
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "2rem" }}>
                        <Col>
                            {categories.map((category, index) => (
                                < CategoriePart
                                    key={index}
                                    index={index}
                                    category={category}
                                    deleteCategory={deleteCategory}
                                />
                            ))}
                        </Col>
                    </Row>
                    {errorMessage &&
                        <ErrorAlert errorMessage={errorMessage} />
                    }
                    <FormGroup className="mb-0" style={{ float: 'right' }}>
                        <Button
                            type="button"
                            color="primary"
                            className="ms-1"
                            onClick={() => { createSurvey() }}
                        >
                            Valider
                        </Button>
                    </FormGroup>
                </div>
            </Modal>
        </>
    )
}
export default CreateSurvey