import React from "react"
import { Redirect } from "react-router-dom"
import UserProfile from "pages/Profile"
import Login from "pages/Authentication/Login"
import UsersList from "pages/User"
import RolesPermissions from "pages/Roles-Permissions"
import NotFoundPage from "shared/NotFoundPage"
import PosList from "pages/Stores"
import Planning from "pages/Planning"
import SurveyParams from "pages/SurveyParams"
import SurveyResponse from "pages/Survey/ResponseComponents/SurveyResponse"
import PlanningControl from "pages/PlanningControl"
import ResponsesDetail from "pages/PlanningControl/components/ResponsesDetail"
import Charts from "pages/Analyses/BarChart"
import SupervisorAnalyse from "pages/Analyses/SupervisorAnalyse"
import ReportSubject from "pages/reportSubject"
import Dashboard from "pages/Dashboard/index"
import Notifications from "pages/Notifications"
import UnauthorizedPage from "shared/UnauthorizedPage"

const userRoutes = [
  // Dashboard route
  { path: "/dashboard", component: Dashboard },
  // User routes
  { path: "/users", component: UsersList },
  { path: "/profile", component: UserProfile },
  // Settings route
  { path: "/permissions", component: RolesPermissions },
  // Store route
  { path: "/stores", component: PosList },
  // Planning routes
  { path: "/planning", component: Planning },
  { path: "/planningControl", component: PlanningControl },
  // Survey routes
  { path: "/surveyParams", component: SurveyParams },
  { path: "/responsesDetail/:responseId", component: ResponsesDetail },
  { path: "/surveyResponse/:surveyId/:storeId/:visitId", component: SurveyResponse },
  // Charts routes
  { path: "/charts", component: Charts },
  { path: "/supervisorAnalyse", component: SupervisorAnalyse },
  // Report object routes
  { path: "/reportSubject", component: ReportSubject },
  // Notificatons routes
  { path: "/notifications", component: Notifications },
  // standard
  { path: "/unauthorized", component: UnauthorizedPage },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/*", exact: true, component: () => <Redirect to="/404" /> },
]

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/404", component: NotFoundPage },
]

export { userRoutes, authRoutes }