import React, { useEffect, useState } from 'react'
import { Col, Label, Row } from 'reactstrap'
import AvField from "availity-reactstrap-validation/lib/AvField";
import Select from "react-select"
import { governorates } from 'shared/TunisiaGovernorates';

const options = [
    { value: "supermarket", label: "supermarket" },
    { value: "hypermarket", label: "hypermarket" },
    { value: "Perfumery", label: "Perfumery" },
]

export default function Form(props) {

    const { store, setStore } = props
    const [selectedGroup, setselectedGroup] = useState(null)

    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup)
    }

    useEffect(() => {
        options.find((str) => {
            if (str.value === store.type)
                setselectedGroup(str)
        });
    }, [store])

    return (
        <Row>
            <Col sm="6">
                <AvField
                    className="mb-3"
                    name="Nom"
                    label="Nom"
                    placeholder="Nom"
                    type="text"
                    errorMessage="Le nom du magasin est obligatoire!"
                    validate={{ required: { value: true } }}
                    value={store.name}
                    onChange={(e) => { setStore({ ...store, name: e.target.value }) }}
                />
                <AvField
                    className="mb-3"
                    name="email"
                    label="Email"
                    placeholder="Email"
                    type="email"
                    errorMessage="L'email est obligatoire!"
                    validate={{
                        required: { value: true },
                        email: { value: true },
                    }}
                    value={store.email}
                    onChange={(e) => { setStore({ ...store, email: e.target.value }) }}
                />
                <AvField
                    className="mb-3"
                    name="addresse"
                    label="Addresse"
                    placeholder="Addresse"
                    type="text"
                    errorMessage="L'addresse est obligatoire!"
                    validate={{ required: { value: true } }}
                    value={store.address}
                    onChange={(e) => { setStore({ ...store, address: e.target.value }) }}
                />
                <AvField
                    className="mb-3"
                    name="Code postal "
                    label="Code postal"
                    placeholder="Code postal"
                    type="text"
                    errorMessage="Le code postal est obligatoire!"
                    validate={{ required: { value: true } }}
                    value={store.postal_code}
                    onChange={(e) => { setStore({ ...store, postal_code: e.target.value }) }}
                />
            </Col>
            <Col sm="6">
                <div className="mb-3">
                    <Label>Type</Label>
                    <Select
                        placeholder="Type"
                        options={options}
                        classNamePrefix="select2-selection"
                        value={selectedGroup}
                        onChange={(e) => { handleSelectGroup(); setStore({ ...store, type: e.label }) }}
                    />
                </div>
                <AvField
                    className="mb-3"
                    name="phone_number"
                    label="Numéro de téléphone"
                    placeholder="Numéro de téléphone"
                    type="number"
                    errorMessage="Le numéro de téléphone est obligatoire!"
                    validate={{
                        required: { value: true },
                        pattern: {
                            value: "^[0-9]{8}$",
                            errorMessage: "Le numéro de téléphone doit être composé de 8 chiffres",
                        },
                    }}
                    value={store.phone_number}
                    onChange={(e) => { setStore({ ...store, phone_number: e.target.value }) }}
                />
                <div className="mb-3">
                    <Label>Governorate</Label>
                    <Select
                        placeholder="Governorate"
                        options={governorates}
                        classNamePrefix="select2-selection"
                        value={{ value: store?.governorate, label: store?.governorate }}
                        onChange={(e) => { setStore({ ...store, governorate: e.label }) }}
                    />
                </div>
            </Col>
        </Row>
    )
}
