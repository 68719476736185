import {
  GET_SURVEYS,
} from "./actions"

const initialState = {
  surveys: null
}

const Survey = (state = initialState, action) => {
  switch (action.type) {
    case GET_SURVEYS:
      state = {
        ...state,
        surveys: action.payload.surveys
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Survey
