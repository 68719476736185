import { api } from "helpers/consts";


export const getSupervisorPerformance = async (from, to, stores, surveys, supervisors, governorates) => {
    let storesId = []
    let supervisorsId = []
    let governoratesId = []
    let surveysId = []
    stores?.forEach((element) => {
        storesId.push(parseInt(element.value))
    })
    supervisors?.forEach((element) => {
        supervisorsId.push(parseInt(element.value))
    })
    governorates?.forEach((element) => {
        governoratesId.push(element.value)
    })
    surveys?.forEach((element) => {
        surveysId.push(element.value)
    })
    const res = await api.get(`/chart/supervisorPerformance/${from}/${to}/${JSON.stringify(storesId)}/${JSON.stringify(surveysId)}/${JSON.stringify(supervisorsId)}/${JSON.stringify(governoratesId)}`);
    return res.data.data;
}

export const getChart = async (stores, surveyId, from, to, supervisors, governorates) => {
    let storesId = []
    let supervisorsId = []
    let governoratesId = []
    stores?.forEach((element) => {
        storesId.push(parseInt(element.value))
    })
    supervisors?.forEach((element) => {
        supervisorsId.push(parseInt(element.value))
    })
    governorates?.forEach((element) => {
        governoratesId.push(element.value)
    })
    const res = await api.get(`/chart/${JSON.stringify(storesId)}/${surveyId}/${from}/${to}/${JSON.stringify(supervisorsId)}/${JSON.stringify(governoratesId)}`);
    return res.data;
}