import { api } from "helpers/consts";

/**
 * 
 * @returns Survey list
 */
export const getSurveys = async () => {
    const res = await api.get(`/survey`);
    return res.data.data;
}

/**
 * 
 * @returns Survey by id
 */
export const getSurveyById = async (id) => {
    const res = await api.get(`/survey/${id}`);
    return res.data.data;
}

/**
 * 
 * @param {Survey} survey 
 * @returns created Survey
 */
export const upsertSurvey = async (survey) => {
    const res = await api.post(`/survey`, survey)
    return res.data;
}

/**
 * 
 * @returns SurveyResponse list
 */
export const getSurveyResponses = async (userId, from, to) => {
    const res = await api.get(`/surveyResponse/${userId}/${from}/${to}`);
    return res.data.data;
}

/**
 * 
 * @returns SurveyResponse by id
 */
export const getSurveyResponseById = async (id) => {
    const res = await api.get(`/surveyResponse/${id}`);
    return res.data.data;
}

/**
 * 
 * @param {SurveyResponse} surveyResponse 
 * @returns created SurveyResponse
 */
export const upsertSurveyResponse = async (surveyResponse, uploaded) => {
    let images = []
    surveyResponse?.responses?.forEach((resp) => {
        resp?.images?.forEach((element) => {
            images.push(element)
        });
    });
    images = [...new Map(images.map(item => [item["path"], item])).values()]

    const formData = new FormData();
    formData.append('surveyResponse', JSON.stringify(surveyResponse))
    images.forEach(element => {
        formData.append(element.path, element)
    });
    const res = await api.post(`/surveyResponse`, formData, {
        onUploadProgress: progressEvent => {
            console.log(Math.round(progressEvent.loaded / progressEvent.total * 100))
        }
    })
    return res.data;
}