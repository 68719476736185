import React, { useEffect, useState } from 'react';
import { MetaTags } from 'react-meta-tags';
import Breadcrumbs from "components/Common/Breadcrumb"
import { Card, CardBody, CardFooter, Col, Container, Row } from 'reactstrap';
import CreateSurvey from './createComponents/CreateSurvey';
import { useDispatch } from 'react-redux';
import { getSurveysAsync } from 'store/survey/actions';
import SurveyDetail from './detailComponents/SurveyDetail';
import LoadingComponent from 'shared/LoadingComponent';

const SurveyParams = () => {

    const dispatch = useDispatch();
    const [surveys, setSurveys] = useState([])

    const getSurveys = async () => {
        setSurveys(dispatch(await getSurveysAsync()).payload.surveys)
    }

    useEffect(async () => {
        await getSurveys()
    }, []);

    return (
        <div className="page-content" >
            <MetaTags>
                <title>Questionnaires</title>
            </MetaTags>
            <Container fluid >
                <Breadcrumbs title="Paramétrages" breadcrumbItem="liste des questionnaires" />
                {surveys.length > 0 ? (
                    <Row>
                        <CreateSurvey
                            getSurveys={getSurveys}
                        />
                        {surveys.map((survey, index) => (
                            <SurveyDetail
                                key={index}
                                survey={survey}
                            />
                        ))}
                    </Row>
                ) : (
                    <LoadingComponent />
                )}

            </Container>
        </div>
    )
}

export default SurveyParams;
