import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import avatarMale from "../../assets/images/users/male-profile-pic.jpg"
import avatarFemale from "../../assets/images/users/female-profile-pic.jpg"
const WelcomeComp = (props) => {

  const { connectedUser, visits } = props
  const [nbRealisedVisits, setNbRealisedVisits] = useState(0)
  const [nbPlannedVisits, setNbPlannedVisits] = useState(0)
  const [avatar, setAvatar] = useState(null)
  let realisedVisits = 0
  let plannedVisits = 0

  useEffect(() => {
    visits.forEach(visit => {

      if (visit?.planned) {
        plannedVisits = plannedVisits + 1
      }
      if (visit?.surveyResponses.length > 0) {
        realisedVisits = realisedVisits + 1
      }
    });
    setNbRealisedVisits(realisedVisits);
    setNbPlannedVisits(plannedVisits)
  }, [visits])

  useEffect(() => {
    if (connectedUser?.gender === 'M' && !connectedUser?.profile_picture) {
      setAvatar(avatarMale);
    } else if (connectedUser?.gender === 'F' && !connectedUser?.profile_picture) {
      setAvatar(avatarFemale);
    }
    else {
      setAvatar(connectedUser?.profile_picture)
    }
  }, [connectedUser])
  


  return (
    <React.Fragment>
      <Card className="overflow-hidden" style={{ width: "100%" }}>
        <div className="bg-primary bg-soft">
          <Row>
            <img
              src={"https://media-exp1.licdn.com/dms/image/C4D1BAQEnNeOSTMDsKw/company-background_10000/0/1618226797572?e=2147483647&v=beta&t=rBbe9KlIfF2tDlmqKh60onlLaION76PX79u-tqbNXeg"}
              className="d-block img-fluid"
              alt={""}
              style={{ height: "8rem" }}
            />
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={avatar}
                  alt=""
                  className="avatar-md rounded-circle img-thumbnail"
                />
              </div>
              <h5 className="font-size-15 text-truncate">{connectedUser?.first_name + " " + connectedUser?.last_name}</h5>
              <p className="text-muted mb-0 text-truncate">{connectedUser?.role?.name}</p>
            </Col>
            <Col sm="8">
              <div className="pt-4">
                <Row style={{ marginBottom: "1rem" }}>
                  <h5 className="font-size-15">{"Information du " + new Date().getDate() + "-" + parseInt((new Date().getMonth()) + 1) + "-" + new Date().getFullYear()}</h5>
                </Row>
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">{nbPlannedVisits}</h5>
                    <p className="text-muted mb-0">Visites plannifiées</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">{nbRealisedVisits}</h5>
                    <p className="text-muted mb-0">Visites réalisées</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
