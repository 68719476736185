import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Modal, Row, Table } from 'reactstrap';
import SubCategoryPart from './SubCategoryPart';

function BarDetail(props) {

    const { modal_standard, setmodal_standard, payload, selectedType } = props
    const [responses, setResponses] = useState([])

    function tog_standard() {
        setmodal_standard(!modal_standard)
        setResponses([])
    }

    function groupByCategory(items) {
        return items.reduce((acc, curr) => {
            if (curr) {
                const { name } = curr?.question?.questionSubCategory?.questionCategory;
                const currentItems = acc[name];
                return {
                    ...acc,
                    [name]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;
        }, {});
    }

    function groupBySubCategory(items) {
        return items.reduce((acc, curr) => {
            if (curr) {
                const { name } = curr?.question?.questionSubCategory;
                const currentItems = acc[name];
                return {
                    ...acc,
                    [name]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;
        }, {});
    }

    useEffect(() => {
        setResponses([])
        payload?.visits?.forEach(visit => {
            visit?.responses?.forEach(element => {
                setResponses(responses => [...responses, element])
            });
        });
    }, [payload])

    return (
        <Modal
            size='lg'
            isOpen={modal_standard}
            toggle={() => { tog_standard() }}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">
                    Détail
                </h5>
                <button
                    type="button"
                    onClick={() => setmodal_standard(false)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                </button>
            </div>
            <div className="modal-body">
                <div className="text-center">
                    <Row>
                        <Col sm={4}>
                            <div>
                                <p className="text-muted mb-2">{selectedType.slice(0, selectedType?.length - 1)}</p>
                                <h5 className="font-size-15">{payload.name}</h5>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div>
                                <p className="text-muted mb-2">Moyenne</p>
                                <h5 className="font-size-15">{payload?.avg}</h5>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="mt-4 mt-sm-0">
                                <p className="text-muted mb-2">Nombre de visit</p>
                                <h5 className="font-size-15">{payload?.visits?.length}</h5>
                            </div>
                        </Col>
                    </Row>
                </div>
                <hr />
                <div className="table-responsive">
                    {Object.keys(groupByCategory(responses)).map((name, index) => {
                        let categoryAvg = 0
                        let totalCoef = 0
                        Object.values(groupByCategory(responses))[index].forEach((res) => {
                            categoryAvg = categoryAvg + (res?.rate * res?.question?.coef)
                            totalCoef = totalCoef + res?.question?.coef
                        })
                        const byCategory = Object.values(groupByCategory(responses))[index]
                        return (
                            <Card key={index} outline color="primary" className="border">
                                <CardHeader className="bg-transparent">
                                    <Table className="table ">
                                        <tbody>
                                            <tr>
                                                <th>{name}</th>
                                                <td style={{ float: "right" }}>{"Moyenne : " + parseFloat(categoryAvg / totalCoef).toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </CardHeader>
                                <CardBody>
                                    {Object.keys(groupBySubCategory(byCategory)).map((name, i) => {
                                        let subCategoryAvg = 0
                                        let totalCoef = 0
                                        Object.values(groupBySubCategory(byCategory))[i].forEach((res) => {
                                            subCategoryAvg = subCategoryAvg + (res?.rate * res?.question?.coef)
                                            totalCoef = totalCoef + res?.question?.coef
                                        })
                                        const bySubCategory = Object.values(groupBySubCategory(byCategory))[i]
                                        return (
                                            <SubCategoryPart
                                                key={i}
                                                name={name}
                                                bySubCategory={bySubCategory}
                                                subCategoryAvg={subCategoryAvg}
                                                totalCoef={totalCoef}
                                            />
                                        )
                                    })}
                                </CardBody>
                            </Card>
                        )
                    })}
                </div>
            </div>
        </Modal>
    )
}

export default BarDetail