import { api } from "helpers/consts";

export const getSurveyResponses = async () => {
    const res = await api.get(`/dashboard/surveyResponses`);
    return res.data.data;
}

export const getVisits = async () => {
    const res = await api.get(`/dashboard/visits`);
    return res.data.data;
}